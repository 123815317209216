import { useMemo } from 'react';

import { IOption } from '@funfarm/kit/types';


export const useOptions = <T extends Record<string, any>, L = string, V = number | string>(values: T[] | undefined, valueName: string, labelName: string): IOption<L, V>[] => {
    return useMemo(() => {
        if(values && Array.isArray(values))
            return values.map((value: T) => ({ label: value[labelName], value: value[valueName] }));

        return [];
    }, [values, labelName, valueName]);
}
