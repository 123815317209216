import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Checkbox, MultiSelect, Paper, Row } from '@funfarm/kit';

import css from 'components/Finances/finances.module.scss';

const WithdrawalsFilter: FC = () => {
    const{
        control,
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    return (
        <Paper className={css.transfersFilter}>
            <Row gap={2}>
                <Controller
                    control={control}
                    name="rooms_id"
                    render={({field}) => (
                        <MultiSelect
                            {...field}
                            className={css.form__input}
                            maxBadges={3}
                            options={[
                                { label: "Luxon", value: "luxon" },
                                { label: "Skrill", value: "skrill" },
                            ]}
                            label="Счёт"
                            labelPosition="inside"
                            checkboxes
                        />
                    )}/>

                <Controller
                    control={control}
                    name="credits"
                    render={({ field }) => (
                        <Checkbox
                            {...field}
                            label="Россия и Беларусь"
                            labelPosition="right"
                            type="radio"
                            checked={!!field.value}
                        />
                    )}/>

                <Controller
                    control={control}
                    name="credits"
                    render={({ field }) => (
                        <Checkbox
                            {...field}
                            label="Остальные страны"
                            labelPosition="right"
                            type="radio"
                            checked={!!field.value}
                        />
                    )}/>
            </Row>
        </Paper>
    );
}

export default WithdrawalsFilter;
