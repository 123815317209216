import React, { FC } from 'react';

import { Badge, Row, Skeleton } from '@funfarm/kit';

import { money } from 'helpers/numbers';

import useBalancesAndBrm from '../../../hooks/useBalancesAndBrm';

const BalanceHistoryFilter: FC = () => {
    const {
        currentPackage,
        isLoading,
        isCurrentPackageError,
    } = useBalancesAndBrm();

    return isLoading ? (
        <Skeleton/>
    ) : !isCurrentPackageError && currentPackage?.package && (
        <Row
            align="center"
            gap={2}
        >
            <Badge color={getColor(currentPackage.package.status)}>
                {getCaption(currentPackage.package.status)}
            </Badge>
            {[
                ["Дата начала", currentPackage.package.datestart],
                // ["Условия", currentPackage.package.condition],
                ["Профит пакета", money(currentPackage.profit.profit, "USD")],
            ].map((item, index) => item[1] && (
                <Badge
                    key={index}
                    color="dark"
                >
                    {item.join(" ")}
                </Badge>
            ))}
        </Row>
    );
};

const getColor = (status: string) =>
    status === "open" ? "green"
        : status === "hold" ? "blue"
            : "default";

const getCaption = (status: string) =>
    status === "open" ? "Пакет открыт"
        : status === "hold" ? "Пакет на расчёте"
            : status === "close" ? "Пакет закрыт"
                : status;

// const PackageStatusBadge: FC = ({ status }: { status: string }) =>
//     status === "open" ? (
//
//     ) : status === "closed" ? (
//
//     ) : (
//
//     )

export default BalanceHistoryFilter;
