import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { differenceInMinutes, max, min, parseISO } from 'date-fns';

import { Badge, IError, Loader, TableCell, TableContext, TableHeader } from '@funfarm/kit';

import { calculateTimeDifference } from 'helpers/timeDifference';
import { ITournament, TPaginationResponse } from 'types';
import { getSchedule } from 'actions';

import { TourContext } from 'components/Tournaments/TourProvider';

import { TournamentRow } from './TournamentRow';

import css from './table.module.scss';


export const SelectedTable = () => {
    const { t } = useTranslation();
    const { filterParams } = useContext(TourContext);
    const { orderedBy } = useContext(TableContext);

    const { isLoading, data } = useQuery<TPaginationResponse<'tourneys', ITournament>, IError>({
        queryKey: ['schedule', 'selected', filterParams, orderedBy],
        queryFn: () => getSchedule({ ...filterParams, selected: true }, orderedBy),
        refetchInterval: 5 * 60 * 1000
    });


    const tourneysCount = useMemo(() => data?.tourneys.length ?? 0, [data]);


    const _duration = useMemo(() => {
        if(!data || data.tourneys.length <= 0)
            return null;

        const startDates = data.tourneys.map(tourney => parseISO(tourney.start_date ?? ''));
        const endDates = data.tourneys.map(tourney => parseISO(tourney.end_date ?? ''));

        const earliestStart = min(startDates);
        const latestEnd = max(endDates);

        const minutesDifference = differenceInMinutes(latestEnd, earliestStart);

        const { hours, minutes } = calculateTimeDifference(minutesDifference);
        let str = '';

        if(hours > 0)
            str += `${hours} ${t('hour', { count: hours })} `;

        if(minutes > 0)
            str += `${minutes} ${t('min', { count: minutes })}`;

        return str;
    }, [data, t]);


    const _rooms = useMemo(() => {
        if(!data || data.tourneys.length <= 0)
            return null;

        const uniqueRooms = new Set(data.tourneys.map(item => item.room));

        return uniqueRooms.size;
    }, [data]);


    const _abi = useMemo(() => {
        if(!data || data.tourneys.length <= 0)
            return null;

        return data.tourneys.reduce((a, t) => a + Number(t.bi), 0);
    }, [data]);


    return (
        <>
            <TableHeader className={css.tournamentsSplitRow}>
                <TableCell>{t('Will play')} <Badge className={css.tournamentsCount} size="small">{tourneysCount}</Badge></TableCell>
            </TableHeader>
            {
                isLoading &&
                <Loader size="small" caption="Fetching table data..." className={css.loader} />
            }
            {
                data && data.tourneys &&
                <>
                    {
                        data.tourneys.map((tournament: any) =>
                            <TournamentRow key={tournament.id} tournament={tournament} selected />)
                    }

                    {/*
                    <TableRow className={css.tournamentsSummary}>
                        <TableCell actions={1} />
                        <TableCell colspan={2} className={css.tournamentsResult}>
                            {t('Summary')}
                        </TableCell>
                        <TableCell colspan={9} className="row gap2">
                            <span className={css.tournamentsResultCount}>
                                {duration}
                            </span>
                            <span className={css.tournamentsResultText}>
                                {
                                    duration &&
                                    t('time spent')
                                }
                            </span>
                        </TableCell>
                        <TableCell colspan={2} className="row gap2">
                            <span className={css.tournamentsResultText}>
                                {
                                    abi &&
                                    t('ABI')
                                }
                            </span>
                            <span className={css.tournamentsResultCount}>
                                {abi}
                            </span>
                        </TableCell>
                        <TableCell colspan={4} className="row gap2">
                            <span className={css.tournamentsResultCount}>
                                {rooms}
                            </span>
                            <span className={css.tournamentsResultText}>
                                {
                                    rooms &&
                                    t('rooms played')
                                }
                            </span>
                        </TableCell>
                        <TableCell />
                    </TableRow>
                    */}
                </>
            }
        </>
    );
};
