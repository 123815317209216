import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, Error, IError, Input, Row } from '@funfarm/kit';
import MoneyInput from '@funfarm/kit/Input/MoneyInput';

import { createWithdrawalRequest } from 'actions/finances';

import css from 'components/Finances/finances.module.scss';
import OperationTypeSelect from 'components/Finances/TransfersHistory/OperationTypeSelect';
import AccountSelect from 'components/Select/AccountSelect';

import useBalancesAndBrm from '../../../hooks/useBalancesAndBrm';

type AddOperationProps = {
    onClose: () => void,
    onSuccess: () => void,
};

const AddOperation: FC<AddOperationProps> = ({
    onClose,
    onSuccess,
}) => {
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const{
        control,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const { refetch, getBalance } = useBalancesAndBrm();

    return (
        <form
            onSubmit={handleSubmit((formData) => {
                setError(undefined);
                const balance = getBalance(Number(formData.account));
                if (balance && formData.operationType !== 1 && Number(formData.amount) > balance) {
                    setError("Недостаточно средств");
                    return;
                }
                setSubmitted(true);
                createWithdrawalRequest(
                    formData.account,
                    Number(formData.amount),
                    formData.operationType,
                    formData.comment,
                )
                    .then(() => {
                        refetch();
                        onSuccess();
                        onClose();
                    })
                    .catch((error: IError) => {
                        setError(error.message);
                    })
                    .finally(() => setSubmitted(false));
            })}
            noValidate
        >
            {error && (
                <Error message={error} view="box" />
            )}
            <AccountSelect control={control} />
            <OperationTypeSelect control={control} />
            <Controller
                control={control}
                name="amount"
                render={({ field }) => (
                    <MoneyInput
                        {...field}
                        label="Сумма"
                        placeholder="Введите нужную сумму"
                        currency="S"
                        onInput={() => setError(undefined)}
                        error={!!errors.amount}
                        required
                    />
                )}
            />
            <Controller
                control={control}
                name="comment"
                render={({ field }) => (
                    <Input
                        {...field}
                        placeholder="Можете дополнить ваш запрос комментарием"
                        // labelPosition="inside"
                    />
                )}
            />
            {/*<div className={css.dropzone}>*/}
            {/*    <UploaderArea*/}
            {/*        label="Переместите скриншот или нажмите для выбора"*/}
            {/*    />*/}
            {/*</div>*/}
            <Row className={css.formButtons}>
                <Button
                    color="default"
                    label="Отмена"
                    loading={submitted}
                    onClick={onClose}
                />
                <Button
                    color="secondary"
                    label="Добавить"
                    type="submit"
                    loading={submitted}
                    disabled={!isValid}
                />
            </Row>
        </form>
    );
};

export default AddOperation;
