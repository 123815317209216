import React, {
    ChangeEventHandler, ForwardedRef, forwardRef,
    InputHTMLAttributes,
    ReactElement,
    useCallback, useRef,
} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import i18n from 'i18next';

import { ELabelPosition, IconCalendar, Input, localeMap } from '@funfarm/kit';

import 'react-datepicker/dist/react-datepicker.css';
import './date.scss';


interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
    disabled?: boolean,
    required?: boolean,
    readonly?: boolean,
    value?: Date | number,
    label?: string,
    labelPosition?: keyof typeof ELabelPosition,
    onChange?: (date: Date | null | number) => void,
    onFocus?: ChangeEventHandler<HTMLInputElement>,
    onBlur?: ChangeEventHandler<HTMLInputElement>,
    onInput?: ChangeEventHandler<HTMLInputElement>,
    className?: string,
    error?: string | boolean,
    displayError?: boolean,
    iconLeft?: ReactElement,
    iconRight?: ReactElement,
    dateFormat?: string,
    maxDate?: Date,
    minDate?: Date,
    showTimeInput?: boolean,
    timeInputLabel?: string
    shouldCloseOnSelect?: boolean
}


export const InputDate = forwardRef((props: IProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
        onChange, dateFormat = 'dd.MM.yyyy', value: _,
        minDate, maxDate, showTimeInput, timeInputLabel,
        shouldCloseOnSelect = false,
        ...rest
    } = props;
    const value = props.value ?
        typeof props.value === 'number' ?
            new Date(props.value) :
            new Date() :
        new Date();

    const lng = i18n.language.slice(0, 2);
    const selectedLocale = localeMap[lng] ?? localeMap['en'];

    registerLocale('locale', selectedLocale);

    const calendarRef = useRef<DatePicker>(null);


    const handleChange = useCallback((date: Date | null) => {
        onChange && onChange(date?.getTime() ?? null);
    }, [onChange]);


    return (
        <>
            <DatePicker
                ref={calendarRef}
                locale="locale" // from i18n
                required={props.required}
                readOnly={props.readonly}
                selected={value}
                closeOnScroll={true}
                {...{ dateFormat, minDate, maxDate, showTimeInput, timeInputLabel }}
                onChange={handleChange}
                shouldCloseOnSelect={shouldCloseOnSelect}
                wrapperClassName="react-datepicker-wrapper"
                calendarClassName="custom-calendar"
                className="custom-datepicker"
                customInput={
                    <Input
                        ref={ref}
                        iconRight={<IconCalendar onClick={() => calendarRef.current?.setOpen(!calendarRef.current.isCalendarOpen())} />}
                        value={value ? format(value, dateFormat) : ''}
                        {...rest}
                    />
                }
            />
        </>
    );
});
