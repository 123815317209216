import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconKnockout = (props: IconProps) => {
    return <Icon id="IconFilterColumn" {...props}>
        <path fill="none" fillRule="evenodd" clipRule="evenodd" d="M3.43957 13.4896L5.20733 11.7219L12.2784 18.7929L10.5106 20.5607C9.92485 21.1465 8.9751 21.1465 8.38932 20.5607L3.43957 15.611C2.85378 15.0252 2.85378 14.0754 3.43957 13.4896L3.43957 13.4896Z" stroke="#ACAFB5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path fill="none" d="M8.74258 9.6006L10.1568 11.0148V11.0148C10.9378 11.7958 12.2042 11.7958 12.9852 11.0148V11.0148C13.7662 10.2338 13.7662 8.96745 12.9852 8.1864L11.2426 6.44374C10.5795 5.7807 9.68025 5.4082 8.74257 5.4082C7.80489 5.4082 6.90561 5.7807 6.24257 6.44374V6.44374C5.57953 7.10678 5.20703 8.00606 5.20703 8.94374V11.7219" stroke="#ACAFB5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path fill="none" d="M12.9844 11.0146L15.8592 13.8894C17.0051 15.0353 18.8629 15.0353 20.0088 13.8894V13.8894" stroke="#ACAFB5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path fill="none" d="M8.69336 5.4062L10.1087 3.99073C11.6816 2.37936 14.1473 2.04056 16.0965 3.16797C18.0302 4.34607 19.6538 5.96986 20.8318 7.90366C21.9587 9.85193 21.6202 12.3165 20.0098 13.8888C18.9618 14.9368 17.7701 16.1285 16.9443 16.9542C16.2737 17.6248 15.426 18.0904 14.5003 18.2965L12.2774 18.7929" stroke="#ACAFB5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>;
};

export default IconKnockout;
