import React, { FC, useContext, useEffect, useState } from 'react';

import { Badge, Button, Dialog, IconChecked, IconClose, Skeleton } from '@funfarm/kit';

import DrawerLayout from 'components/Finances/DrawerLayout';
import ClosePackage from 'components/Finances/PlayerPackages/ClosePackage';
import PackagesTable from 'components/Finances/PlayerPackages/PackagesTable';
import { NavigationContext } from 'components/Layout/NavigationProvider';

import useBalancesAndBrm from '../../../hooks/useBalancesAndBrm';

import css from '../finances.module.scss';

const PlayersPackages: FC = () => {
    const [completingPackageOpen, setCompletingPackageOpen] = useState(false);
    const [packageJustClosed, setPackageJustClosed] = useState(false);
    const { closeDrawer } = useContext(NavigationContext);
    const {
        currentPackage,
        isLoading,
        isCurrentPackageError,
    } = useBalancesAndBrm();

    useEffect(() => {
        if (completingPackageOpen) {
            setInterval(
                () => setPackageJustClosed(false),
                5000,
            )
        }
    }, [completingPackageOpen])

    return (
        <DrawerLayout
            pageHeadingProps={{
                title: "Пакеты игрока",
                renderButtons: () => (
                    <>
                        {isLoading ? (
                            <Skeleton/>
                        ) : !isCurrentPackageError && currentPackage?.package && (
                            <>
                                {packageJustClosed && (
                                    <Badge
                                        color="green"
                                        icon={<IconChecked/>}
                                    >
                                        Заявка на расчёт принята. Расчёт будет сделан в течение 72 часов
                                    </Badge>
                                )}
                                {currentPackage.package.status === "open" && (
                                    <Button
                                        color="secondary"
                                        onClick={() => setCompletingPackageOpen(true)}
                                    >
                                        Закрыть текущий пакет
                                    </Button>
                                )}
                                {completingPackageOpen && (
                                    <Dialog
                                        header="Закрыть пакет"
                                        open={completingPackageOpen}
                                        onClose={() => setCompletingPackageOpen(false)}
                                    >
                                        <ClosePackage
                                            onSuccess={() => setPackageJustClosed(true)}
                                            onClose={() => setCompletingPackageOpen(false)}
                                        />
                                    </Dialog>
                                )}
                            </>
                        )}
                        <Button
                            view="contained"
                            color="default"
                            size="xlarge"
                            className={css.drawerClose}
                            icon={
                                <IconClose fill="white" size="xlarge" />
                            }
                            onClick={closeDrawer}
                        />
                    </>
                )
            }}
        >
            <PackagesTable/>
        </DrawerLayout>
    );
};

export default PlayersPackages;
