export const calculateDateDifference = (createdAt: string) => {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);

    let years = currentDate.getFullYear() - createdDate.getFullYear();
    let months = currentDate.getMonth() - createdDate.getMonth();
    let days = currentDate.getDate() - createdDate.getDate();

    if(days < 0) {
        months--;
        days += new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
    }

    if(months < 0) {
        years--;
        months += 12;
    }

    return { years, months, days };
};


export const calculateTimeDifference = (duration: number) => {
    const currentDate = new Date();
    const targetDate = new Date(currentDate.getTime() - duration * 60 * 1000); // Конвертируем минуты в миллисекунды

    let hours = currentDate.getHours() - targetDate.getHours();
    let minutes = currentDate.getMinutes() - targetDate.getMinutes();

    if(minutes < 0) {
        hours--;
        minutes += 60;
    }

    if(hours < 0) {
        hours += 24; // Это актуально, если дни уже учтены отдельно.
    }

    return { hours, minutes };
};
