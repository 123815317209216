import React, { HTMLAttributes, useContext } from 'react';
import classNames from 'classnames';

import { TableContext, ucFirst } from '@funfarm/kit';

import css from './table.module.scss';


interface Props extends HTMLAttributes<HTMLDivElement> {
    id?: string,
    actions?: number,
    nowrap?: boolean,
    align?: string,
    valign?: string,
    colspan?: number | 'auto' | 'grow' | 'shrink',
    direction?: 'row' | 'column',
}

export const TableCell = (props: Props) => {
    const {
        actions, nowrap, valign, align, colspan, direction = 'row',
        className, style, id,
        ...rest
    } = props;
    const { tableColumns } = useContext(TableContext);


    if(id && tableColumns && !tableColumns.includes(id))
        return null;


    return (
        <div {...rest} className={classNames(
            css.cell,
            (colspan && css[`colspan${ucFirst(colspan)}`]),
            (valign && css[`valign${ucFirst(valign)}`]),
            (align && css[`align${ucFirst(align)}`]),
            (nowrap && css.nowrap),
            (actions && [css.actions, css[`action${actions}`]]),
            (direction && css[`direction${ucFirst(direction)}`]),
            className,
        )}
        style={style}
        onClick={(e) => {
            actions && e.stopPropagation();
        }}
        >
            {props.children}
        </div>
    );
};

export default TableCell;
