import { IOption } from '@funfarm/kit/types';

type IOptionValue = IOption['value'];


export const getSelectedValues = (value: IOptionValue, values: IOptionValue[]): IOptionValue[] => {
    return values.some((item: IOptionValue) => String(item) === String(value)) ?
        values.filter((item: IOptionValue) => String(item) !== String(value)) :
        values.concat(value);
};
