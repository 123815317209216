import React from 'react';

import { Button } from '@funfarm/kit';

import { timestampToDate } from 'helpers/formatDates';
import { money } from 'helpers/numbers';
import { WithdrawalRequest } from 'types/finances';

import css from '../finances.module.scss';

const withdrawalsColumns = (
    openSendMoneyDialog: (withdrawalRequest: WithdrawalRequest) => void
) => ([
    {
        header: 'Дата',
        colspan: 2,
        fieldName: 'datestart',
        renderCell: (item: WithdrawalRequest) => timestampToDate(item.datestart),
    },
    {
        header: "Счёт",
        fieldName: "room",
        renderCell: (item: WithdrawalRequest) => item.room.title,
    },
    {
        header: 'Данные счёта',
        colspan: 5,
        fieldName: 'toname',
    },
    {
        header: 'Сумма',
        fieldName: 'amount',
        renderCell: (item: WithdrawalRequest) => (
            <span className={Number(item.amount) > 0 ? css.green : css.red}>
                {Number(item.amount) > 0 && "+ "}
                {money(item.amount, item.currency.abrname)}
            </span>
        ),
    },
    {
        header: 'Валюта',
        fieldName: 'currency',
        renderCell: (item: WithdrawalRequest) => item.currency.abrname,
    },
    {
        header: '',
        fieldName: 'send',
        sortable: false,
        colspan: 2,
        renderCell: (item: WithdrawalRequest) => (
            <>
                <Button
                    view="outlined"
                    size="medium"
                    color="secondary"
                    className={css.textBtn}
                    label={`Отправить ${item.currency.abrname}`}
                    onClick={() => openSendMoneyDialog(item)}
                />
            </>
        ),
    },
]);

// const getColor = (status: string) =>
//     status === 'Проведено'
//         ? 'green'
//         // : status === 'На рассчёте'
//         //     ? 'orange'
//         //     : status === 'Закрыт'
//         //         ? 'green'
//         : 'default';

export default withdrawalsColumns;
