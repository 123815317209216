import React, { useState } from 'react';
import { Controller,useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { signIn } from 'supertokens-auth-react/recipe/emailpassword';

import { Button, Error, Input, useGlobalState } from '@funfarm/kit';

import { IUser } from 'types';
import { getUser } from 'actions';

import { TwoFA } from './TwoFA';

import css from './signin.module.css';


interface IProps {
    twoFA?: boolean
}

export const SignIn = (props: IProps) => {
    const { twoFA } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [, setUser] = useGlobalState<IUser>('user');

    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [twoFAState, setTowFA] = useState<boolean>(!!twoFA);

    const defaultValues = {
        userName: '',
        password: '',
    };

    const {
        control,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues
    });


    const onSubmit = (formData: typeof defaultValues) => {
        setSubmitted(true);

        return signIn({
            formFields: [
                { id: 'email', value: formData.userName },
                { id: 'password', value: formData.password },
            ],
        })
            .then((response) => {
                if(response.status !== 'OK') {
                    return Promise.reject({ code: 400, message: t(response.status) });
                }

                return getUser();
            })
            .then((user) => {
                setUser(user);

                if(user.mfa_enabled && !user.mfa_passed)
                    setTowFA(true);
                else
                    navigate(0);

                return true;
            })
            .catch((error) => {
                setError(t(error.message || 'Unknown error'));

                return false;
            })
            .finally(() => setSubmitted(false));
    };


    if(twoFAState)
        return <TwoFA />


    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate className={css.form}>
            <h1>{t('Welcome back')}</h1>
            {
                error &&
                <Error message={error} view="box" />
            }
            <Controller
                control={control}
                name="userName"
                rules={{
                    required: t('User name is required'),
                }}
                render={({ field }) => (
                    <Input
                        {...field} label={t('User name')} placeholder="youremail@domain.com" required error={!!errors.userName} />
                )} />
            <Controller
                control={control}
                name="password"
                rules={{
                    required: t('Password is required'),
                }}
                render={({ field }) => (
                    <Input
                        {...field} type="password" label={t('Password')} required error={!!errors.password} />
                )} />
            <div className={css.buttonWrapper}>
                <Button color="secondary" type="submit" loading={submitted} disabled={!isValid}>{t('Login')}</Button>
            </div>
        </form>
    );
};
