import React from 'react';
import { Outlet } from 'react-router-dom';

import { TournamentsHeader } from './TournamentsHeader';
import { TourProvider } from './TourProvider';


export const Tournaments = () => {
    return (
        <TourProvider>
            <TournamentsHeader />
            <Outlet />
        </TourProvider>
    );
}
