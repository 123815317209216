import React, { useContext } from 'react';
import classNames from 'classnames';

import { IconSortDown, IconSortUp, TableContext } from '@funfarm/kit';
import { EOrderDirection, IOrderedBy } from '@funfarm/kit/types';

import css from './sort.module.scss';


interface IProps {
    fieldName: string,
    orderedBy?: IOrderedBy,
    orderBy?: (fieldName: string, orderDirection: EOrderDirection) => void
}


export const ActionSort = (props: IProps) => {
    const { fieldName, orderedBy: externalOrderedBy, orderBy: externalOrderBy } = props;
    let { orderedBy, orderBy } = useContext(TableContext);

    orderBy = externalOrderBy ?? orderBy;
    orderedBy = externalOrderedBy ?? orderedBy;


    return (
        orderedBy && orderedBy[fieldName] === EOrderDirection.asc ?
            <IconSortDown className={classNames(css.sort, css.active)} onClick={() => orderBy && orderBy(fieldName, EOrderDirection.desc)} /> :
            orderedBy && orderedBy[fieldName] === EOrderDirection.desc ?
                <IconSortUp className={classNames(css.sort, css.active)} onClick={() => orderBy && orderBy(fieldName, EOrderDirection.asc)} /> :
                <IconSortDown className={css.sort} onClick={() => orderBy && orderBy(fieldName, EOrderDirection.asc)} />
    );
};
