import { API } from "helpers/apiInstance";
import { ITrainingPlayer, ITrainingReportForm } from "types/index";

export const getSubjects = () => {
    return API.get<any, { filters: [] }>("/trainings/subjects")
        .then(response => response);
};
export const getGroups = () => {
    return API.get<any, { filters: [] }>("/trainings/groups")
        .then(response => response);
};
export const getPlayersInGroup = (groupID: string): Promise<ITrainingPlayer[]> => {
    return API.get<ITrainingPlayer[], { filters: [] }>(`/trainings/groups/${groupID}/players`)
        .then(response => response);
};
export const getAllPlayers = (): Promise<ITrainingPlayer[]> => {
    return API.get<ITrainingPlayer[], { filters: [] }>(`/trainings/groups/players`)
        .then(response => response);
};

export const createReport = (data: ITrainingReportForm): Promise<any> => {
    return API.post<ITrainingReportForm, any>(`/trainings/reports`, data)
}


