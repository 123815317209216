import React from "react";

import {TableCell} from "@funfarm/kit";
import TableHeader from "@funfarm/kit/Table/TableHeader";

export const LimitsTableHeader = () => {
    return (
        <TableHeader>
            <TableCell colspan={2}/>
            <TableCell colspan={2}>
                Регспид
            </TableCell>
            <TableCell colspan={2}>
                6-max турбо
            </TableCell>
            <TableCell colspan={2}>
                7-9-max турбо
            </TableCell>
            <TableCell colspan={2}>
                Регспид 6-max
            </TableCell>
            <TableCell colspan={2}>
                Регспид 7-9max
            </TableCell>
            <TableCell colspan={2}>
                Турбо
            </TableCell>
        </TableHeader>
    )
}
