import React, {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import classNames from "classnames";

import {Error, IconPokerDom, IconViewGrid, IError, Loader, Table} from "@funfarm/kit";
import IconDeviceMobile from "@funfarm/kit/Icon/icons/DeviceMobile";

import {ILimitsParams} from "types/index";
import {getLimits} from "actions/tournaments";

import {LimitsTableHeader} from "components/Tournaments/TournamentsLimits/LimitsTableHeader";
import {LimitsTableRow} from "components/Tournaments/TournamentsLimits/LimitsTableRow";

import css from './limits.module.scss'

export const TournamentsLimits = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleClick = (index: number) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const {isLoading, isError, error, data, isFetching} = useQuery<ILimitsParams[], IError>({
        queryKey: ['limits'],
        queryFn: () => getLimits()
    });

    if (isError)
        return <Error layout {...error} />

    if (isLoading)
        return <Loader size="small" caption="Fetching table data..." className={css.loader}/>


    return (
        <section>
            <h2>Лимиты для румов, которых нет в основной таблице</h2>
            <div className={isFetching && css.fetching}>
                {data?.map((item, i) => {
                    const isActive = activeIndex === i;
                    return (
                        <div key={i} className={css.limitsAccordion}>
                            <div
                                onClick={() => handleClick(i)}
                                className={css.limitsHeader}
                            >
                                {item.room === 'PD' && <IconPokerDom/>}
                                {item.room === 'Mobile Apps' && <IconDeviceMobile size='xxlarge'/>}
                                {item.room === 'Другое' && <IconViewGrid size='xxlarge'/>}
                                <h3 className={classNames(css.limitsTitle, isActive && css.limitsTitleOpen)}>{item.room}</h3>
                            </div>
                            {activeIndex === i && (
                                <div className={css.limitsContent}>
                                    <Table cells={14} className={css.limitsTable}>
                                        <LimitsTableHeader/>
                                        <LimitsTableRow limit={item}/>
                                    </Table>
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        </section>
    )
}
