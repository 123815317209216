import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconOn = (props: IconProps) => {
    return <Icon id="IconOn" fill="none" viewBox="0 0 20 20" {...props}>
        <path
            d="M12.1876 3.75C13.8485 4.27244 15.2678 5.37153 16.1892 6.84879C17.1107 8.32606 17.4736 10.0841 17.2123 11.8055C16.9511 13.5269 16.0829 15.0981 14.7647 16.2355C13.4464 17.3728 11.7649 18.0013 10.0238 18.0075C8.28273 18.0136 6.59683 17.397 5.27057 16.269C3.9443 15.141 3.06509 13.5759 2.7917 11.8564C2.51832 10.1369 2.86878 8.37631 3.77979 6.89258C4.69081 5.40885 6.10233 4.29977 7.75947 3.76563"
            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M10 1.9751V8.29697" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </Icon>;
};

export default IconOn;
