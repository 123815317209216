import React, { ReactElement } from 'react';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { useQuery } from '@tanstack/react-query';

import { Select } from '@funfarm/kit';

import { CalculationConditionsResponse } from 'types/finances';
import { getCalculationConditions } from 'actions/finances';

type NextPackageProps = {
    field: ControllerRenderProps;
};

const NextPackage = ({ field }: NextPackageProps): ReactElement => {
    const {} = useQuery<CalculationConditionsResponse>({
        queryKey: ["finances", "calculation-conditions"],
        queryFn: () => getCalculationConditions(),
    });

    return (
        <div>
            <Select
                {...field}
                label="Cледующий пакет"
                required
                options={[
                    { label: "0%, 50%", value: 1, },
                    { label: "10%, 35%", value: 2, },
                    { label: "0%, 25%", value: 3, },
                ]}
            />
        </div>
    );
};

export default NextPackage;
