import React, { FC, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Button, Dialog, Error, IconPlus, Table, useTable } from '@funfarm/kit';
import IconHistory from '@funfarm/kit/Icon/icons/History';
import TableRenderer from '@funfarm/kit/Table/TableRenderer';

import { WithdrawalRequest, WithdrawalRequestsResponse } from 'types/finances';
import { getWithdrawalRequests } from 'actions/finances';

import FinanceHeading from 'components/Finances/FinanceHeading';
import css from 'components/Finances/finances.module.scss';
import TableSkeleton from 'components/Finances/TableSkeleton';
import TransactionsHistory from 'components/Finances/TransactionsHistory/TransactionsHistory';
import CreateRequest from 'components/Finances/Withdrawals/CreateRequest';
import SendMoney from 'components/Finances/Withdrawals/SendMoney';
import withdrawalsColumns from 'components/Finances/Withdrawals/withdrawals.columns';
import WithdrawalsFilter from 'components/Finances/Withdrawals/WithdrawalsFilter';
import { NavigationContext } from 'components/Layout/NavigationProvider';
import PageHeading from 'components/PageHeading/PageHeading';
import { TourContext } from 'components/Tournaments/TourProvider';

const WithdrawalRequests: FC = () => {
    const tableContext = useContext(TourContext);
    const tableState = useTable({
        pagination: { take: 50, skip: 0 },
        order: { start_date: 'asc' },
    });
    const { data, isLoading, isError, refetch } = useQuery<WithdrawalRequestsResponse>({
        queryKey: ["finances", "withdrawalRequests"],
        queryFn: () => getWithdrawalRequests()
    });
    const { openDrawer } = useContext(NavigationContext);
    const [newRequestOpened, setNewRequestOpened] = useState(false);
    const [sendMoneyRequest, setSendMoneyRequest] = useState<WithdrawalRequest>();

    return (
        <>
            <FinanceHeading/>
            <PageHeading
                title="Активные запросы"
                tag="h2"
                renderButtons={() => (
                    <>
                        <Button
                            color="default"
                            onClick={() => openDrawer!(<TransactionsHistory/>)}
                            iconLeft={<IconHistory/>}
                        >
                            История переводов
                        </Button>
                        <Button
                            color="secondary"
                            iconLeft={<IconPlus/>}
                            onClick={() => setNewRequestOpened(true)}
                        >
                            Создать новый запрос
                        </Button>
                        {newRequestOpened && (
                            <Dialog
                                header="Создать запрос"
                                open={newRequestOpened}
                                onClose={() => setNewRequestOpened(false)}
                            >
                                <CreateRequest
                                    onClose={() => setNewRequestOpened(false)}
                                    onSuccess={refetch}
                                />
                            </Dialog>
                        )}
                    </>
                )}
            />
            <WithdrawalsFilter/>
            {isLoading ? (
                <TableSkeleton />
            ) : (isError || !data?.requests) ? (
                <Error message="Ошибка получения данных" />
            ) : (
                <Table
                    cells={8}
                    {...tableContext}
                    {...tableState}
                >
                    <TableRenderer
                        rows={data.requests}
                        columns={withdrawalsColumns(setSendMoneyRequest)}
                        classNames={{
                            header: css.balanceHistoryHeader,
                            row: css.balanceHistoryRow,
                        }}
                    />
                    {sendMoneyRequest && (
                        <Dialog
                            header="Отправка денег"
                            open={!!sendMoneyRequest}
                            onClose={() => setSendMoneyRequest(undefined)}
                        >
                            <SendMoney
                                request={sendMoneyRequest}
                                onClose={() => setSendMoneyRequest(undefined)}
                                onSuccess={refetch}
                            />
                        </Dialog>
                    )}
                </Table>
            )}
        </>
    );
}

export default WithdrawalRequests;
