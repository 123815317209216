import React, { FC } from 'react';

import { Checkbox, Paper, Row } from '@funfarm/kit';
import { InputDateRange } from '@funfarm/kit/InputDate/InputDateRange';

import css from 'components/Finances/finances.module.scss';

type TransfersFilterProps = {
    startDate?: Date;
    endDate?: Date;
    operationType: "in" | "out" | undefined;
    setStartDate: (value: Date | undefined) => void,
    setEndDate: (value: Date | undefined) => void,
    setOperationType: (value: "in" | "out" | undefined) => void,
}

const TransfersFilter: FC<TransfersFilterProps> = ({
    startDate,
    endDate,
    operationType,
    setStartDate,
    setEndDate,
    setOperationType,
}) => {
    return (
        <Paper className={css.transfersFilter}>
            <Row gap={2}>
                <div>
                    <InputDateRange
                        placeholder="Период"
                        className={css.dateRangeInput}
                        value={[startDate, endDate]}
                        // startDate={undefined}
                        // endDate={undefined}
                        label="Период"
                        dateFormat="dd.MM.yyyy"
                        labelPosition="inside"
                        onChange={([startDate, endDate]) => {
                            setStartDate(startDate);
                            setEndDate(endDate);
                        }}
                    />
                </div>

                <Checkbox
                    label="Пополнение"
                    labelPosition="right"
                    checked={operationType === "in"}
                    onClick={() => setOperationType("in")}
                />
                <Checkbox
                    label="Списание"
                    labelPosition="right"
                    checked={operationType === "out"}
                    onClick={() => setOperationType("out")}
                />
            </Row>
        </Paper>
    );
}

export default TransfersFilter;
