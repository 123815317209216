import React from 'react';

import { Paper } from '@funfarm/kit';

import { FilterInputs } from './FilterInputs';
import { FilterTemplates } from './FilterTemplates';

import css from '../tournaments.module.scss'


export const TournamentsFilters = () => {
    return (
        <Paper className={css.filter}>
            <FilterTemplates />
            <FilterInputs />
        </Paper>
    )
}
