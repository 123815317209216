import React, { FC, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Error, Table, useTable } from '@funfarm/kit';
import TableRenderer from '@funfarm/kit/Table/TableRenderer';

import { FinanceCalculation } from 'types/finances';
import { getCalculations } from 'actions/finances';

import css from 'components/Finances/finances.module.scss';
import playersPackageColumns from 'components/Finances/PlayerPackages/playersPackage.columns';
import TableSkeleton from 'components/Finances/TableSkeleton';
import { TourContext } from 'components/Tournaments/TourProvider';

const PackagesTable: FC = () => {
    const tableContext = useContext(TourContext);
    const tableState = useTable({
        pagination: { take: 50, skip: 0 },
        order: { start_date: 'asc' },
    });
    const { data, isLoading, isError } = useQuery<{ calculations: FinanceCalculation[] } >({
        queryKey: ["finances", "calculations"],
        queryFn: () => getCalculations()
    });

    return isLoading ? (
        <TableSkeleton />
    ) : (isError || !data?.calculations) ? (
        <Error message="Ошибка получения данных" />
    ) : (
        <Table
            cells={8}
            className={css.balanceHistoryTable}
            {...tableContext}
            {...tableState}
        >
            <TableRenderer
                rows={data.calculations}
                columns={playersPackageColumns}
                classNames={{
                    header: css.balanceHistoryHeader,
                    row: css.balanceHistoryRow,
                }}
            />
        </Table>
    );
};

export default PackagesTable;
