import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, Error, IError, Input, Row, UploaderArea } from '@funfarm/kit';
import MoneyInput from '@funfarm/kit/Input/MoneyInput';
import WarningPaper from '@funfarm/kit/Paper/WarningPaper';

import { WithdrawalRequest } from 'types/finances';
import { createSendMoneyRequest } from 'actions/finances';

import css from 'components/Finances/finances.module.scss';

type SendMoneyProps = {
    request: WithdrawalRequest;
    onClose: () => void;
    onSuccess: () => void;
}

const SendMoney: FC<SendMoneyProps> = ({
    request,
    onClose,
    onSuccess,
}) => {
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const {
        control,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
    });

    return (
        <form
            onSubmit={handleSubmit((formData) => {
                setSubmitted(true);
                createSendMoneyRequest(
                    request.id,
                    formData.amount,
                    formData.fee,
                    formData.link,
                    // screenshot_url?: string,
                )
                    .then((response) => {
                        if (response.data.errors?.length) {
                            setError(Object.values(response.data.errors).join("\n"));
                        } else {
                            onClose();
                            onSuccess();
                        }
                    })
                    .catch((error: IError) => {
                        setError(error.message);
                    })
                    .finally(() => setSubmitted(false));
            })}
            noValidate
        >
            {error && (
                <Error message={error} view="box" />
            )}
            <p>
                Вы отправляете пользователю {request.user.name} на счёт {request.room.title}
            </p>

            <Controller
                control={control}
                name="amount"
                render={({ field }) => (
                    <MoneyInput
                        {...field}
                        label="Сумма отправки"
                        placeholder="Введите сумму"
                        currency={request.currency.symbol}
                        onInput={() => setError(undefined)}
                        error={!!errors.amount}
                        required
                    />
                )}
            />
            <Controller
                control={control}
                name="fee"
                render={({ field }) => (
                    <MoneyInput
                        {...field}
                        label="Комиссия"
                        placeholder="Заполните, если есть комиссия"
                        currency={request.currency.symbol}
                        onInput={() => setError(undefined)}
                        error={!!errors.fee}
                    />
                )}
            />

            <WarningPaper>
                Обязательно вставьте ссылку на транзакцию или прикрепите скриншот
            </WarningPaper>

            <Controller
                control={control}
                name="link"
                render={({ field }) => (
                    <Input
                        {...field}
                        label="Ссылка на транзакцию"
                        placeholder="Вставьте ссылку на транзакцию"
                        error={!!errors.link}
                    />
                )}
            />

            <div className={css.dropzone}>
                <UploaderArea
                    label="Переместите скриншот или нажмите для выбора"
                />
            </div>

            <Row className={css.formButtons}>
                <Button
                    color="default"
                    loading={submitted}
                    label="Отмена"
                    onClick={() => onClose()}
                />
                <Button
                    color="secondary"
                    type="submit"
                    loading={submitted}
                    disabled={!isValid}
                    label="Отправить деньги"
                />
            </Row>
        </form>
    );
};

export default SendMoney;
