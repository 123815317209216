import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, Error, IError, Input, Row } from '@funfarm/kit';
import WarningPaper from '@funfarm/kit/Paper/WarningPaper';

import { money } from 'helpers/numbers';
import { openNewPackage } from 'actions/finances';

import NextPackage from 'components/Finances/PlayerPackages/NextPackage';

import useBalancesAndBrm from '../../../hooks/useBalancesAndBrm';

import css from '../finances.module.scss';

type ClosePackageProps = {
    onClose: () => void,
    onSuccess?: () => void;
};

const ClosePackage: FC<ClosePackageProps> = ({
    onClose,
    onSuccess,
}) => {
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const{
        control,
        handleSubmit,
        formState: { isValid }
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        // defaultValues: filterParams
    });
    const { currentPackage } = useBalancesAndBrm();

    return (
        <div className={css.closePackageForm}>
            <form
                onSubmit={handleSubmit((formData) => {
                    setSubmitted(true);
                    openNewPackage(formData.condition)
                        .then(() => {
                            onSuccess && onSuccess();
                            onClose();
                        })
                        .catch((error: IError) => {
                            typeof error?.message === "string" && setError(error.message);
                        })
                        .finally(() => setSubmitted(false));
                })}
                noValidate
            >
                {error && (
                    <Error message={error} view="box" />
                )}
                <WarningPaper>
                    Прежде чем подавать заявку на расчёт убедитесь, что данные по балансам актуальны и summary турниров
                    загружены
                </WarningPaper>
                <Input
                    label="Профит текущего пакета"
                    value={
                        money(Number(currentPackage?.profit.profit), "USD") || "-"
                    }
                    iconRight={
                        <span className={css.currencyInner}>$</span>
                    }
                    disabled
                />
                <Input
                    label="Tекущий пакет"
                    value="0%, 50%"
                    description="% от байина, % от профита"
                    disabled
                />

                <Controller
                    control={control}
                    name="condition"
                    render={NextPackage}
                />
                <Row className={css.formButtons}>
                    <Button
                        color="default"
                        label="Отмена"
                        loading={submitted}
                        onClick={onClose}
                    />
                    <Button
                        color="secondary"
                        label="Закрыть пакет"
                        type="submit"
                        loading={submitted}
                        disabled={!isValid}
                    />
                </Row>
            </form>
        </div>
    );
};

export default ClosePackage;

