import React, { ForwardedRef, forwardRef, HTMLAttributes, MouseEvent, useContext } from 'react';
import classNames from 'classnames';

import { TabsContext } from '@funfarm/kit/Tabs/TabsProvider';

import css from './tabs.module.scss';


interface IProps extends HTMLAttributes<HTMLDivElement> {
    index: number | string,
    label?: string,
    disabled?: boolean,
    // ref?: RefObject<HTMLDivElement>,

}

export const TabHeader = forwardRef((props: IProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        index, disabled, label,
        className, style,
        ...rest
    } = props;
    const { tabChecked, setTabChecked, setLabel } = useContext(TabsContext);


    const handleClick = (_event: MouseEvent<HTMLDivElement>) => {
        setTabChecked && setTabChecked(index);
        setLabel && label && setLabel(label);
    };


    return (
        <div {...rest}
            className={classNames(
                css.tabHeader,
                (tabChecked === index && css.active),
                (disabled && css.disabled),
                className,
            )}
            id={index as string}
            style={style}
            onClick={handleClick}
            ref={ref}
        >
            {props.children}
        </div>
    );
});
