import { ReactNode, useEffect, useState } from 'react';

import { Loader, useGlobalState } from '@funfarm/kit';

import { IUser } from 'types';
import { getUser } from 'actions';


export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [user, setUser] = useGlobalState<IUser>('user');
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if(user && !loading)
            return;

        getUser()
            .then(user => setUser(user))
            .catch(() => console.error('getUser error'))
            .finally(() => setLoading(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    if(loading)
        return <Loader layout />;

    return children;
};

