import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconCalendar = (props: IconProps) => {
    return <Icon  id="IconCalendar" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.5 1.5C9.05228 1.5 9.5 1.94772 9.5 2.5V3.5H14.5V2.5C14.5 1.94772 14.9477 1.5 15.5 1.5C16.0523 1.5 16.5 1.94772 16.5 2.5V3.5H22C22.5523 3.5 23 3.94772 23 4.5V22.5C23 23.0523 22.5523 23.5 22 23.5H2C1.44772 23.5 1 23.0523 1 22.5V4.5C1 3.94772 1.44772 3.5 2 3.5H7.5V2.5C7.5 1.94772 7.94772 1.5 8.5 1.5ZM7.5 5.5H3V21.5H21V5.5H16.5V6.5C16.5 7.05228 16.0523 7.5 15.5 7.5C14.9477 7.5 14.5 7.05228 14.5 6.5V5.5H9.5V6.5C9.5 7.05228 9.05228 7.5 8.5 7.5C7.94772 7.5 7.5 7.05228 7.5 6.5V5.5ZM5 10.5C5 9.94772 5.44772 9.5 6 9.5H10C10.5523 9.5 11 9.94772 11 10.5V17.5C11 18.0523 10.5523 18.5 10 18.5H6C5.44772 18.5 5 18.0523 5 17.5C5 16.9477 5.44772 16.5 6 16.5H9V15H7C6.44772 15 6 14.5523 6 14C6 13.4477 6.44772 13 7 13H9V11.5H6C5.44772 11.5 5 11.0523 5 10.5ZM13 10.5C13 9.94772 13.4477 9.5 14 9.5H18C18.5523 9.5 19 9.94772 19 10.5V17.5C19 18.0523 18.5523 18.5 18 18.5H14C13.4477 18.5 13 18.0523 13 17.5V10.5ZM15 11.5V16.5H17V11.5H15Z" />
    </Icon>;
};

export default IconCalendar;
