import React, { forwardRef } from 'react';

import NumberInput, { NumberInputProps } from './NumberInput';

import css from './input.module.scss';

export type MoneyInputProps = NumberInputProps & {
    currency: string;
};

const MoneyInput = forwardRef<HTMLInputElement, MoneyInputProps>(
    (
        { currency, ...props },
        ref,
    ) => (
        <NumberInput
            {...props}
            min={0}
            precision={2}
            ref={ref}
            iconRight={
                <span className={css.currencyInner}>
                    {currency}
                </span>
            }
        />
    )
);

export default MoneyInput;
