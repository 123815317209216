import React from 'react';

import { Badge } from '@funfarm/kit';

import { timestampToDate } from 'helpers/formatDates';
import { money, symbolToCurrencyCode } from 'helpers/numbers';
import { FinanceTransaction } from 'types/finances';

import css from 'components/Finances/finances.module.scss';

const transactionColumns = [
    {
        header: "Дата",
        fieldName: "date",
        renderCell: (item: FinanceTransaction) => timestampToDate(item.date),
    },
    {
        header: "Сумма",
        fieldName: "amount",
        colspan: 3,
        renderCell: (item: FinanceTransaction) => (
            <span className={Number(item.amount) > 0 ? css.green : css.red}>
                {Number(item.amount) > 0 && "+ "}
                {money(
                    item.amount,
                    symbolToCurrencyCode(item.currency)
                )}
            </span>
        ),
    },
    {
        header: "Валюта",
        fieldName: "currency",
        colspan: 1,
        renderCell: (item: FinanceTransaction) => symbolToCurrencyCode(item.currency),
    },
    {
        header: "Счёт",
        fieldName: "account",
        colspan: 3,
        renderCell: (item: FinanceTransaction) => item?.room?.toLowerCase().includes("skrill") ? (
            <img src="/logo/skrill.png" width={46} height={15} alt="skrill"/>
        ) : item?.room?.toLowerCase().includes("luxon") ? (
            <img src="/logo/luxon.png" width={76} height={20} alt="luxor"/>
        ) : (
            <span>{item.room}</span>
        ),
    },
    {
        header: "Тип",
        fieldName: "type",
        colspan: 8,
    },
    {
        header: "Остаток",
        fieldName: "curramount",
        colspan: 3,
        renderCell: (item: FinanceTransaction) =>
            money(
                item.curramount,
                symbolToCurrencyCode(item.currency)
            ),
    },
    {
        header: "Статус",
        fieldName: "status",
        renderCell: (item: FinanceTransaction) => (
            <div style={{ overflow: "hidden" }}>
                <Badge
                    size="small"
                    color={getColor(item.modcom)}
                >
                    {item.modcom}
                </Badge>
            </div>
        ),
    },
    // {
    //     header: "Коммент",
    //     fieldName: "comment",
    //     renderCell: (item: FinanceTransaction) => (
    //         <div style={{ overflow: "hidden" }}>
    //             {item.comment}
    //         </div>
    //     ),
    // },
    {
        header: "",
        fieldName: "actions",
    },
];

const getColor = (status: string) =>
    status === 'Проведено'
        ? 'green'
        : status.includes('Удален')
            ? 'red'
            : 'default';

export default transactionColumns;
