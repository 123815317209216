import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconViewGrid = (props: IconProps) => {
    return <Icon id="IconDocumentCopy" {...props} viewBox='0 0 32 32'>
        <path
            fill='none'
            d="M5.33594 7.99992C5.33594 6.52716 6.52984 5.33325 8.0026 5.33325H10.6693C12.142 5.33325 13.3359 6.52716 13.3359 7.99992V10.6666C13.3359 12.1393 12.142 13.3333 10.6693 13.3333H8.0026C6.52984 13.3333 5.33594 12.1393 5.33594 10.6666V7.99992Z"
            stroke="#22B183" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            fill='none'
            d="M18.6693 7.99992C18.6693 6.52716 19.8632 5.33325 21.3359 5.33325H24.0026C25.4754 5.33325 26.6693 6.52716 26.6693 7.99992V10.6666C26.6693 12.1393 25.4754 13.3333 24.0026 13.3333H21.3359C19.8632 13.3333 18.6693 12.1393 18.6693 10.6666V7.99992Z"
            stroke="#22B183" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            fill='none'
            d="M5.33594 21.3333C5.33594 19.8605 6.52984 18.6666 8.0026 18.6666H10.6693C12.142 18.6666 13.3359 19.8605 13.3359 21.3333V23.9999C13.3359 25.4727 12.142 26.6666 10.6693 26.6666H8.0026C6.52984 26.6666 5.33594 25.4727 5.33594 23.9999V21.3333Z"
            stroke="#22B183" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            fill='none'
            d="M18.6693 21.3333C18.6693 19.8605 19.8632 18.6666 21.3359 18.6666H24.0026C25.4754 18.6666 26.6693 19.8605 26.6693 21.3333V23.9999C26.6693 25.4727 25.4754 26.6666 24.0026 26.6666H21.3359C19.8632 26.6666 18.6693 25.4727 18.6693 23.9999V21.3333Z"
            stroke="#22B183" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Icon>;
};

export default IconViewGrid;
