import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconPokerDom = (props: IconProps) => {
    return <Icon id="IconDocumentCopy" {...props}>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.319995 0C0.143134 0 0 0.143548 0 0.32V15.5833C0 20.2052 3.78195 23.9872 8.40419 23.9872C12.6382 23.9872 16.1647 20.8136 16.7278 16.728C20.8132 16.1649 23.9865 12.6379 23.9865 8.40432C23.9865 3.782 20.2049 0 15.5827 0H0.319995ZM4.63746 15.5833V4.79753C4.63746 4.7091 4.70903 4.63753 4.79746 4.63753H15.5827C17.5228 4.63753 19.3494 6.1565 19.3494 8.40432C19.3494 10.3144 17.9226 12.1337 15.5913 12.1337L12.4695 12.1518C12.2943 12.1526 12.1524 12.2945 12.1512 12.4697L12.1335 15.5916C12.1335 17.9233 10.3143 19.3497 8.40419 19.3497C6.15682 19.3497 4.63746 17.5235 4.63746 15.5833ZM18.47 21.2352C18.47 22.762 19.7077 24 21.2348 24C22.7616 24 23.9996 22.762 23.9996 21.2352C23.9996 19.708 22.7616 18.4703 21.2348 18.4703C19.7077 18.4703 18.47 19.708 18.47 21.2352Z"
              fill="#22B183"/>
    </Icon>;
};

export default IconPokerDom;
