import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconChecked = (props: IconProps) => {
    return <Icon id="IconChecked" viewBox="0, 0, 16, 16" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.3536 5.53037L6.85356 11.0301C6.6583 11.2253 6.34173 11.2253 6.14647 11.0301L3.14648 8.0301L3.85359 7.32299L6.50003 9.96942L11.6465 4.82324L12.3536 5.53037Z" />
    </Icon>;
};

export default IconChecked;
