import React from 'react';
import classNames from 'classnames';

import { IconError, IconSuccess } from '@funfarm/kit';

import css from './progress.module.scss';


interface IProps {
    progress: number,
    progressState?: 'run' | 'success' | 'fail'
    title?: string
}

export const ProgressBar = (props: IProps) => {
    const { progressState, progress, title } = props;


    const renderBar = () => {
        return (
            <div className={css.bar}>
                <div className={classNames(css.filler, css[progressState as string])} style={{ width: `${progress}%` }}/>
            </div>
        );
    };


    const renderState = () => {
        return (
            <div className={css.state}>
                {
                    progressState === 'run' &&
                    <>
                        {
                            title &&
                            <span>{title}</span>
                        }
                        <span>{progress}%</span>
                    </>
                }
                {
                    progressState === 'success' &&
                    <>
                        <span>Completed</span>
                        <IconSuccess className={css.success} />
                    </>
                }
                {
                    progressState === 'fail' &&
                    <>
                        <span>Error</span>
                        <IconError className={css.error} />
                    </>
                }
            </div>
        );
    };


    return (
        <div className={css.progress}>
            { renderState() }
            { renderBar() }
        </div>
    );
};
