import React, {
    CSSProperties,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    RefObject,
    useEffect,
    useRef,
    useState
} from 'react';
import classNames from 'classnames';

import { Backdrop, Button, IconClose, Portal, useOnClickOutside } from '@funfarm/kit';

import css from './dialog.module.scss';


interface IProps {
    id?: string,
    header?: string,
    backdrop?: boolean,
    open: boolean,
    className?: string,
    style?: CSSProperties,
    buttons?: ReactElement[],
    onClose?: () => void,
    children?: ReactNode,
    actions?: Record<string, (() => boolean | void) | MouseEventHandler<HTMLButtonElement>>,
    wide?: boolean,
    xlarge?: boolean,
    position?: 'relative' | 'fixed' | 'absolute'
    positionRef?: RefObject<HTMLElement>,
}

export const Dialog = (props: IProps) => {
    const {
        id = 'dialog-portal',
        header, backdrop = true, open,
        onClose, buttons, actions,
        className, style, wide, xlarge,
        position = 'fixed', positionRef,
        ...rest
    } = props;

    const bodyRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const [hasScroll, setHasScroll] = useState<boolean>(false);
    const [relativeStyles, setRelativeStyles] = useState<React.CSSProperties>({});
    const [animationOpen, setAnimationOpen] = useState<boolean>(true);
    const [animationClose, setAnimationClose] = useState<boolean>(false);


    useEffect(() => {
        if(open) {
            setAnimationOpen(false);
            setAnimationClose(true);
        } else {
            setAnimationOpen(true);

            setTimeout(() => {
                setAnimationClose(false);

                if(onClose)
                    onClose();
            }, 300);
        }
    }, [open, onClose]);


    // if onButtonClick returns false Dialog wouldn't close
    const handleClose = async (onButtonClick?: () => Promise<boolean>) => {
        let closeAfter;

        if(typeof onButtonClick === 'function') {
            closeAfter = await onButtonClick();
        }

        if(onClose && closeAfter !== false) {
            setAnimationOpen(true);

            setTimeout(() => {
                setAnimationClose(false);

                onClose();
            }, 300);
        }
    };


    useOnClickOutside(wrapperRef, () => handleClose());


    useEffect(() => {
        if(positionRef?.current && open) {
            const rect = positionRef.current.getBoundingClientRect();
            const computedStyles = window.getComputedStyle(positionRef.current);

            const paddingTop = parseFloat(computedStyles.paddingTop || '0');
            const marginTop = parseFloat(computedStyles.marginTop || '0');

            const scrollTop = window.scrollY;

            // Устанавливаем абсолютное позиционирование относительно `position`
            setRelativeStyles({
                top: `calc(${rect.top + rect.height + marginTop + paddingTop + scrollTop}px + 0.25rem)`,
                left: `${rect.left}px`,
            });
        }
    }, [position, open, positionRef]);


    const checkScroll = () => {
        if(bodyRef.current) {
            setHasScroll(bodyRef.current.scrollHeight > bodyRef.current.clientHeight);
        }
    }

    useEffect(() => {
        if(open) {
            checkScroll();
        }
    }, [open, props.children]);


    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            checkScroll();
        });

        if(bodyRef.current) {
            resizeObserver.observe(bodyRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);



    return (
        <>
            {
                (open || animationClose) &&
                <Portal id={id}>
                    {
                        backdrop && <Backdrop open={open} />
                    }
                    <div className={classNames(
                        (!animationOpen && animationClose) && css.open,
                        css.dialog,
                        wide && css.wide,
                        xlarge && css.xlarge,
                        positionRef && css.relative,
                        position && css[position],
                        className,
                    )} {...rest} style={{ ...style, ...relativeStyles }} ref={wrapperRef}>
                        {
                            header !== undefined ?
                                <div className={css.header}>
                                    <h3 className={css.title}>{header}</h3>
                                    <IconClose onClick={() => handleClose()} size="large" className={css.icon} />
                                </div> :
                                null
                        }
                        {
                            props.children ?
                                <div
                                    className={classNames(css.body, hasScroll && css.hasScroll)}
                                    ref={bodyRef}
                                >
                                    {props.children}
                                </div> :
                                null
                        }
                        {
                            // мутная тема
                            actions &&
                            <div className={css.actions}>
                                {
                                    Object.keys(actions).map((action) => {
                                        return (
                                            <>
                                                <Button onClick={() => actions[action]} view="outlined">{action}</Button>,
                                                <Button key="delete" onClick={() => actions[action]}>{action}</Button>
                                            </>
                                        );
                                        // return React.cloneElement(
                                        //     button,
                                        //     { key, onClick: () => handleClose(button.props.onClick) }
                                        // );
                                    })
                                }
                            </div>
                        }
                        {
                            buttons && buttons.length &&
                            <div className={css.actions}>
                                {
                                    buttons.map((button, key) => {
                                        return React.cloneElement(
                                            button,
                                            { key, onClick: () => handleClose(button.props.onClick) }
                                        );
                                    })
                                }
                            </div>
                        }
                    </div>
                </Portal>
            }
        </>
    );
};
