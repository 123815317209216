import React, { FC, ReactElement, useContext } from 'react';

import { Button } from '@funfarm/kit';
import Metrics, { MetricsProps } from '@funfarm/kit/Metrics';

import css from 'components/Finances/finances.module.scss';
import { NavigationContext } from 'components/Layout/NavigationProvider';
import PageHeading from 'components/PageHeading/PageHeading';

type MetricsSectionProps = {
    title: string,
    button: string,
    buttonIcon: ReactElement,
    drawerContent: ReactElement,
    items: MetricsProps[],
    isLoading?: boolean;
};

const MetricsSection: FC<MetricsSectionProps> = ({
    title,
    button,
    buttonIcon,
    drawerContent,
    items,
    isLoading = false,
}) => {
    const { openDrawer } = useContext(NavigationContext);
    return (
        <>
            <PageHeading
                title={title}
                renderButtons={() => (
                    <Button
                        iconLeft={buttonIcon}
                        label={button}
                        onClick={() => openDrawer!(drawerContent)}
                    />
                )}
                tag="h2"
            />
            <div className={css.metricsContainer}>
                {items.map((item, i) => (
                    <Metrics
                        {...item}
                        isLoading={isLoading}
                        key={i}
                    />
                ))}
            </div>
        </>
    );
}

export default MetricsSection;
