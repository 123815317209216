export const inTheWay = [
    {
        wallet: "Доллары",
        date: "01.08 12:01",
        value: 20,
        currency: "₽",
    },
    {
        wallet: "Евро",
        date: "01.08 12:01",
        value: 211,
        currency: "₽",
    },
    {
        wallet: "USDT",
        date: "01.08 12:01",
        value: 1404,
        currency: "₽",
    },
];

export const roomNames: Record<number, string> = {
    5: "PokerStars",
    6: "PokerStars.ES",
    7: "PartyPoker",
    8: "888",
    11: "IPoker €",
    15: "WPN",
    16: "WMX",
    17: "Chico",
    18: "GG",
};
