import React, { useContext,useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';

import { IconEye, IconEyeOff, IconPlus, IError, TableContext,Toast } from '@funfarm/kit';

import { postTourneysHide, postTourneysShow, postWillPlay } from 'actions';

import { TourContext } from 'components/Tournaments/TourProvider';

import css from './fab.module.scss';


interface IProps {
    open?: boolean
}

export const FloatingAction = (props: IProps) => {
    const { open } = props;
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { rowsChecked, setRowsChecked } = useContext(TableContext);
    const { filterParams } = useContext(TourContext);

    const [submitted, setSubmitted] = useState<boolean>(false);


    const handleWillPlay = () => {
        if(!rowsChecked || filterParams?.show_hidden)
            return;

        setSubmitted(true);

        return postWillPlay(rowsChecked)
            .then(() => {
                setRowsChecked && setRowsChecked([]);
                queryClient.invalidateQueries({ queryKey: ['schedule'] });
            })
            .catch((error: IError) => {
                console.error(error);

                Toast.error({ message: error.message });

                return false;
            })
            .finally(() => setSubmitted(false));
    };


    const handleHide = () => {
        if(!rowsChecked)
            return;

        setSubmitted(true);

        return postTourneysHide(rowsChecked)
            .then(() => {
                setRowsChecked && setRowsChecked([]);
                queryClient.invalidateQueries({ queryKey: ['schedule'] });
            })
            .catch((error: IError) => {
                console.error(error);

                Toast.error({ message: error.message });

                return false;
            })
            .finally(() => setSubmitted(false));
    };


    const handleShow = () => {
        if(!rowsChecked)
            return;

        setSubmitted(true);

        return postTourneysShow(rowsChecked)
            .then(() => {
                setRowsChecked && setRowsChecked([]);
                queryClient.invalidateQueries({ queryKey: ['schedule'] });
            })
            .catch((error: IError) => {
                console.error(error);

                Toast.error({ message: error.message });

                return false;
            })
            .finally(() => setSubmitted(false));
    };


    return (
        <div className={
            classNames(
                css.fab,
                open && css.open
            )
        }>
            <div className={classNames(css.button, submitted && css.submitted, filterParams?.show_hidden && css.disabled)} onClick={handleWillPlay}>
                <IconPlus />{t('Will play')}
            </div>
            {
                filterParams?.show_hidden ?
                    <div className={classNames(css.button, submitted && css.submitted)} onClick={handleShow}>
                        <IconEye />{t('Show')}
                    </div> :
                    <div className={classNames(css.button, submitted && css.submitted)} onClick={handleHide}>
                        <IconEyeOff />{t('Hide')}
                    </div>
            }
        </div>
    );
};
