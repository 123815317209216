import React, { FC, useState } from 'react';

import { Button, Dialog, Error, Paper } from '@funfarm/kit';
import { Column } from '@funfarm/kit/Column';

import { BalanceItem } from 'types/finances';

import TableSkeleton from 'components/Finances/TableSkeleton';
import CreateRequest from 'components/Finances/Withdrawals/CreateRequest';

import useBalancesAndBrm from '../../../hooks/useBalancesAndBrm';
import BalanceHistory from '../BalanceHistory/BalanceHistory';

import BalanceLine from './BalanceLine';

import css from '../finances.module.scss';

const BalancePanel: FC = () => {
    const [historyOpen, setHistoryOpen] = useState(false);
    const [balanceToChange, setBalanceToChange] = useState<BalanceItem>();

    const { balances, inTheWay, isLoading, isBalancesError } = useBalancesAndBrm();

    return (
        <div className={css.balancePanel}>
            <Paper
                header="Баланс"
                helper={
                    <Button
                        view="text"
                        size="small"
                        color="secondary"
                        onClick={() => setHistoryOpen(true)}
                    >
                        Посмотреть историю
                    </Button>
                }
            >
                {isLoading ? (
                    <TableSkeleton columns={3}/>
                ) : (isBalancesError || !balances.length || !inTheWay.length) ? (
                    <Error message="Ошибка получения данных" />
                ) : (
                    <Column gap={3}>
                        {!isLoading && balances.map((details: BalanceItem, index) => (
                            <BalanceLine
                                item={details}
                                openChangeBalance={(item: BalanceItem) => setBalanceToChange(item)}
                                key={index}
                            />
                        ))}
                        {!isLoading && inTheWay.length && (
                            <>
                                <hr />
                                <h3>В пути</h3>
                                {inTheWay.map((details, index) => (
                                    <BalanceLine
                                        item={details}
                                        openChangeBalance={(item: BalanceItem) => setBalanceToChange(item)}
                                        key={index}
                                    />
                                ))}
                            </>
                        )}
                        {balanceToChange && (
                            <Dialog
                                header="Создать запрос"
                                open={!!balanceToChange}
                                onClose={() => setBalanceToChange(undefined)}
                            >
                                <CreateRequest
                                    balance={balanceToChange}
                                    onClose={() => setBalanceToChange(undefined)}
                                />
                            </Dialog>
                        )}
                    </Column>
                )}
            </Paper>

            {historyOpen && (
                <Dialog
                    header="История заполнения балансов"
                    open={historyOpen}
                    onClose={() => setHistoryOpen(false)}
                    xlarge
                >
                    <BalanceHistory/>
                </Dialog>
            )}
        </div>
    );
};

export default BalancePanel;
