import React, { FC } from 'react';

import { Column } from '@funfarm/kit/Column';

import FinanceHeading from 'components/Finances/FinanceHeading';

import FinanceMetrics from '../FinanceMetrics';

import BalancePanel from './BalancePanel';

import css from '../finances.module.scss';

const FinanceBalance: FC = () => {
    return (
        <>
            <FinanceHeading/>
            <div className={css.financesGrid}>
                <Column className={css.balancePanel} gap={2}>
                    <BalancePanel/>
                    {/*<CrossRatesPanel/>*/}
                </Column>
                <FinanceMetrics/>
            </div>
        </>
    );
}

export default FinanceBalance;
