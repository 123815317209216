import React, { createContext, Dispatch, ReactNode, RefObject, SetStateAction, useCallback, useState } from 'react';

import { EOrderDirection, IFilteredBy, IOption, IOrderedBy, IPaginationParams } from '@funfarm/kit/types';

import { useTable } from './useTable';


export interface ITableProviderProps {
    tableRef: null | RefObject<HTMLDivElement>,
    children?: ReactNode,
    order?: IOrderedBy,
    orderedBy?: IOrderedBy,
    orderBy?: (fieldName: string, orderDirection: EOrderDirection) => void,
    defaultOrderedBy?: string,
    defaultOrderedType?: EOrderDirection,
    filter?: IFilteredBy,
    filteredBy?: IFilteredBy,
    filterBy?: (fieldName: string, filterValues?: IFilteredBy) => void,
    clearFilters?: () => void,
    filterValues?: {
        [key: string]: IOption[]
    },
    addFilterValues?: (fieldName: string, options: IOption[]) => void,
    tableColumns?: string[],
    setTableColumns?: Dispatch<SetStateAction<string[]>>,
    rowsChecked?: Record<string, any>[],
    setRowsChecked?: Dispatch<SetStateAction<Record<string, any>[]>>,
    // rowsChecked?: (string | number | Record<string, any>)[],
    // setRowsChecked?: Dispatch<SetStateAction<(string | number | Record<string, any>)[]>>,
    columns?: number
    pagination?: IPaginationParams,
    setPagination?: Dispatch<SetStateAction<IPaginationParams>>,
}

const defaultContextValue = {
    tableRef: null,
};

const TableContext = createContext<ITableProviderProps>(defaultContextValue);

const TableProvider = (props: ITableProviderProps) => {
    const {
        tableRef,
        orderBy, orderedBy,
        filterBy, filteredBy, clearFilters,
        rowsChecked, setRowsChecked,
        tableColumns, setTableColumns,
        pagination, setPagination
    } = props;

    const [filterValues, setFilterValues] = useState<ITableProviderProps['filterValues']>();


    const addFilterValues = useCallback((fieldName: string, options: IOption[]) => {
        setFilterValues(prevValues => ({ ...prevValues, [fieldName]: options }));
    }, [setFilterValues]);


    return (
        <TableContext.Provider value={{
            ...defaultContextValue,
            tableRef,
            orderedBy, orderBy,
            filteredBy, filterBy, filterValues, addFilterValues, clearFilters,
            tableColumns, setTableColumns,
            rowsChecked, setRowsChecked,
            pagination, setPagination
        }}>
            {props.children}
        </TableContext.Provider>
    );
};


export { TableProvider, useTable, TableContext };
