import React, { useContext } from 'react';

import {
    Table,
    TableCell,
    TableHeader,
    useTable
} from '@funfarm/kit';

import { TourContext } from 'components/Tournaments/TourProvider';

import { ScheduleTable } from './ScheduleTable';
import { SelectedTable } from './SelectedTable';

import css from './table.module.scss';


export const TournamentsTable = () => {
    const { tableColumns, setTableColumns } = useContext(TourContext);

    const { rowsChecked, setRowsChecked, orderedBy, orderBy, pagination, setPagination } = useTable({
        pagination: { take: 50, skip: 0 },
        order: { start_date: 'asc' }
    });

    return (
        <>
            <Table cells={28}
                className={css.tournamentsTable}
                {...{ orderedBy, orderBy, tableColumns, setTableColumns, rowsChecked, setRowsChecked, pagination, setPagination }}
            >
                <TableHeader>
                    <TableCell actions={1} />
                    <TableCell colspan={2}>
                        Рум
                        <Table.ActionSort fieldName="room" />
                    </TableCell>
                    <TableCell colspan={5}>
                        Название
                        <Table.ActionSort fieldName="name" />
                    </TableCell>
                    <TableCell colspan={4}>
                        Дата начала
                        <Table.ActionSort fieldName="start_date" />
                    </TableCell>
                    <TableCell colspan={2}>
                        Buy-in
                        <Table.ActionSort fieldName="bi" />
                    </TableCell>
                    <TableCell colspan={2} id="rank">
                        Ранг
                        <Table.ActionSort fieldName="rank" />
                    </TableCell>
                    <TableCell colspan={3} id="end_date">
                        Дата завершения
                        <Table.ActionSort fieldName="end_date" />
                    </TableCell>
                    <TableCell colspan={1} id="type" align="center">Тип</TableCell>
                    <TableCell colspan={2} id="vinrate">
                        Винрейт
                        <Table.ActionSort fieldName="vinrate" />
                    </TableCell>
                    <TableCell colspan={2} id="fishes">
                        Фиши
                        <Table.ActionSort fieldName="fishes" />
                    </TableCell>
                    <TableCell colspan={2} id="tournament">О турнире</TableCell>
                    <TableCell colspan={3} />
                </TableHeader>

                <SelectedTable />

                <ScheduleTable />

            </Table>
        </>
    );
}
