import { differenceInMinutes, format, isAfter,isBefore, isToday, parseISO } from 'date-fns';
import { t } from 'i18next';

import i18n, { localeMap } from './i18n';


export function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
    let timer: NodeJS.Timeout | null = null;

    return function(...args: Parameters<T>) {
        if(timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            func(...args);
        }, delay);
    };
}


export const displayStartDate = (startDate: string | null, endDate?: string | null) => {
    if(!startDate || !endDate)
        return null;

    const now = new Date();
    const start = parseISO(startDate ?? '');
    const end = parseISO(endDate ?? '');

    if(isBefore(now, startDate)) {
        const minutesToStart = differenceInMinutes(start, now);

        if(minutesToStart < 60) {
            return `${t('In')} ${minutesToStart} ${t('min', { count: minutesToStart })}`;
        }

        if(isToday(startDate))
            return '';

        return '';
    }

    if(isAfter(now, end))
        return t('Finished');

    const minutesElapsed = differenceInMinutes(now, start);

    if(minutesElapsed < 60)
        return `${t('Running')} ${minutesElapsed} ${t('min', { count: minutesElapsed })}`;

    return `${t('Running')}`;
}


export const displayDate = (endDate: string | null) => {
    if(!endDate)
        return null;

    const date = parseISO(endDate ?? '');

    if(isToday(endDate))
        return format(date, 'HH:mm', { locale: localeMap[i18n.language] });

    return format(date, 'd LLL HH:mm', { locale: localeMap[i18n.language] });
}
