import React, { FC, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Error, Table, useTable } from '@funfarm/kit';
import TableRenderer from '@funfarm/kit/Table/TableRenderer';

import { BalanceHistoryResponse } from 'types/finances';
import { getBalancesHistory } from 'actions/finances';

import prepareBalanceHistoryColumns from 'components/Finances/BalanceHistory/balanceHistory.columns';
import TableSkeleton from 'components/Finances/TableSkeleton';
import { TourContext } from 'components/Tournaments/TourProvider';

import BalanceHistoryFilter from './BalanceHistoryFilter';

import css from '../finances.module.scss';

const BalanceHistory: FC = () => {
    const tableContext = useContext(TourContext);
    const { tableColumns, setTableColumns } = useContext(TourContext);
    const { rowsChecked, setRowsChecked, orderedBy, orderBy, pagination, setPagination } = useTable({
        pagination: { take: 50, skip: 0 },
        order: { start_date: 'asc' }
    });
    const historyResponse = useQuery<BalanceHistoryResponse>({
        queryKey: ["finances", "balanceHistory"],
        queryFn: () => getBalancesHistory()
    });
    // const roomsResponse = useQuery<RoomsResponse>({
    //     queryKey: ["finances", "rooms"],
    //     queryFn: () => getRooms()
    // });
    // const roomPropsMap = useMemo(() => {
    //     if (!roomNamesMap || historyResponse.isLoading || !historyResponse.data?.balances) return {};
    //     historyResponse.data?.balances
    //     return Object.fromEntries(
    //         roomsResponse.data.rooms.map(
    //             (room) => ([ room.id, room.name ])
    //         )
    //     );
    // }, [roomNamesMap]);

    return (
        <>
            <BalanceHistoryFilter/>
            {historyResponse.isLoading ? (
                <TableSkeleton />
            ) : (historyResponse.isError || !historyResponse.data?.balances.length) ? (
                <Error message="Ошибка получения данных" />
            ) : (
                <Table
                    cells={8}
                    className={css.balanceHistoryTable}
                    {...tableContext}
                    {...{orderedBy, orderBy, tableColumns, setTableColumns, rowsChecked, setRowsChecked, pagination, setPagination }}
                >
                    <TableRenderer
                        rows={historyResponse.data.balances}
                        columns={prepareBalanceHistoryColumns(
                            historyResponse.data.balances[0].room_balances
                        )}
                        classNames={{
                            header: css.balanceHistoryHeader,
                            row: css.balanceHistoryRow,
                        }}
                    />
                </Table>
            )}
        </>
    );
};

export default BalanceHistory;
