import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';

import { Error, ISelectProps, Select, Skeleton } from '@funfarm/kit';

import { UsedRoomsResponse } from 'types/finances';
import { getUsedRooms } from 'actions/finances';

import useBalancesAndBrm from '../../hooks/useBalancesAndBrm';

type AccountSelectProps = Partial<ISelectProps> & {
    control: any,
};

const AccountSelect: FC<AccountSelectProps> = ({
    control,
}) => {
    const { data, isLoading, isError } = useQuery<UsedRoomsResponse>({
        queryKey: ["rooms", "used"],
        queryFn: () => getUsedRooms()
    });
    const { getBalance } = useBalancesAndBrm();

    return isLoading ? (
        <Skeleton />
    ) : (isError || !data?.rooms) ? (
        <Error message="Ошибка получения данных" />
    ) : (
        <Controller
            control={control}
            name="account"
            render={({ field }) => (
                <Select
                    {...field}
                    label="Выбор счёта"
                    required
                    options={
                        data.rooms
                            .filter(room => room.allowtransfer)
                            .map((room) => ({
                                label: `${room.title}    (${getBalance(room.id)}$)`,
                                value: room.id,
                            }))
                    }
                />
            )}
        />
    );
};

export default AccountSelect;
