import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconError = (props: IconProps) => {
    return <Icon id="IconError" {...props}>
        <path d="M11.25 9.00004C11.25 8.58582 11.5858 8.25004 12 8.25004C12.4142 8.25004 12.75 8.58582 12.75 9.00004V13.5C12.75 13.9143 12.4142 14.25 12 14.25C11.5858 14.25 11.25 13.9143 11.25 13.5V9.00004Z" />
        <path d="M12 18C12.6213 18 13.125 17.4964 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4964 11.3787 18 12 18Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.9753 3.48887L21.7119 17.6726C22.5297 19.172 21.4445 21 19.7366 21H4.26343C2.55555 21 1.47034 19.172 2.28817 17.6726L10.0248 3.48887C10.8775 1.92542 13.1225 1.92542 13.9753 3.48887ZM12.6584 4.20714C12.3742 3.686 11.6259 3.686 11.3416 4.20715L3.60501 18.3909C3.3324 18.8907 3.69414 19.5 4.26343 19.5H19.7366C20.3059 19.5 20.6677 18.8907 20.395 18.3909L12.6584 4.20714Z" />
    </Icon>;
};

export default IconError;
