import React from "react";

import {Table, TableCell} from "@funfarm/kit";
import TableHeader from "@funfarm/kit/Table/TableHeader";

import css from "components/Tournaments/TournamentsAll/table.module.scss";

export const PermissionTableHeader = () => {
    return (
        <TableHeader className={css.tournamentsHeader}>
            <TableCell id="room">
                Рум
                <Table.ActionSort fieldName="room" />
            </TableCell>
            <TableCell colspan={4} id="name">
                Название
                <Table.ActionSort fieldName="name" />
            </TableCell>
            <TableCell colspan={2} id="date_tourney" align="center">
                Дата начала
                <Table.ActionSort fieldName="date_tourney" />
            </TableCell>
            <TableCell colspan={2} id="bi" align="center">
                Buy-in
                <Table.ActionSort fieldName="bi" />
            </TableCell>
            <TableCell id="answer">
                Ответ
                <Table.ActionSort fieldName="answer" />
            </TableCell>
            <TableCell colspan={3} />
        </TableHeader>
    )
}
