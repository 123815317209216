import React from 'react';
import { t } from 'i18next';

import { Badge, EColors } from '@funfarm/kit';

import { durationInDays, formatPeriod } from 'helpers/formatDates';
import { money } from 'helpers/numbers';

import { PlayerPackageItem } from 'components/Finances/finances.types';

const playersPackageColumns = [
    {
        header: "Период",
        renderCell: (item: PlayerPackageItem) => formatPeriod(item.date_start, item.date_end),
    },
    {
        header: "Длительность",
        renderCell: (item: PlayerPackageItem) => {
            const count = durationInDays(item.date_start, item.date_end);
            return count + " " + t('day', { count });
        },
    },
    {
        header: "Условия",
        fieldName: "condition",
    },
    {
        header: "Результат, $",
        renderCell: (item: PlayerPackageItem) =>
            money(item.profit, 'USD'),
    },

    {
        header: "Доля игрока, $",
        renderCell: (item: PlayerPackageItem) =>
            money(item.profit_user, 'USD'),
    },
    {
        header: "Статус пакета",
        fieldName: "packageStatus",
        renderCell: (item: PlayerPackageItem) => (
            <Badge
                size="small"
                color={statusColors[item.package_status] || "default"}
            >
                {t("package_status_" + item.package_status)}
            </Badge>
        ),
    },
];

const statusColors: Record<string, keyof typeof EColors> = {
    open: "blue",
    close: "green",
    cancell: "red",
    final: "default",
    hold: "orange",
};

export default playersPackageColumns;
