import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


interface IContextProps {
    children?: ReactNode,
    mobileExpanded?: boolean,
    setMobileExpanded?: Dispatch<SetStateAction<boolean>>,

    drawerOpened?: boolean,
    drawerContent?: ReactNode,
    openDrawer?: (content: ReactNode) => void,
    closeDrawer?: () => void,
}

const defaultContextValue = {};

const NavigationContext = createContext<IContextProps>(defaultContextValue);

const NavigationProvider = (props: IContextProps) => {
    const [mobileExpanded, setMobileExpanded] = useState<boolean>(false);
    const [drawerOpened, setDrawerOpened] = useState<boolean>(false);
    const [drawerContent, setDrawerContent] = useState<ReactNode>(<></>);
    const location = useLocation();


    useEffect(() => {
        setMobileExpanded(false);
    }, [location]);

    return (
        <NavigationContext.Provider value={{
            mobileExpanded,
            setMobileExpanded,

            drawerOpened,
            drawerContent,
            closeDrawer: () => setDrawerOpened(false),
            openDrawer: (content: ReactNode) => {
                setDrawerContent(content);
                setDrawerOpened(true);
            },
        }}>
            {props.children}
        </NavigationContext.Provider>
    );
};

export { NavigationProvider, NavigationContext };
