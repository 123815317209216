import React from "react";
import {useQuery} from "@tanstack/react-query";
import classNames from "classnames";

import {Error, IError, Loader, Table} from "@funfarm/kit";

import {IPermissionsParams} from "types/index";
import {getPermissions} from "actions/tournaments";

import css from "components/Tournaments/TournamentsAll/table.module.scss";
import {PermissionTableHeader} from "components/Tournaments/TournamentsPermissions/PermissionTableHeader";
import {PermissionTableRow} from "components/Tournaments/TournamentsPermissions/PermissionTableRow";

export const PermissionTable = () => {

    const { isLoading, isError, error, data, isFetching} = useQuery<IPermissionsParams[], IError>({
        queryKey: ['permissions'],
        queryFn: () => getPermissions()
    });

    if(isError)
        return <Error layout {...error} />

    if(isLoading)
        return <Loader size="small" caption="Fetching table data..." className={css.loader} />

    return (
        <>
            <Table className={classNames(css.tournamentsTable, isFetching && css.fetching)} cells={13}>
                <PermissionTableHeader/>
                {data?.map((permission, index: number) => {
                    return (
                        <PermissionTableRow permission={permission} key={index}/>
                    )
                })}
            </Table>
        </>
    )
}
