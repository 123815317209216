import React, { FC, useMemo } from 'react';

import { Error } from '@funfarm/kit';
import IconDocumentText from '@funfarm/kit/Icon/icons/DocumentText';
import IconHistory from '@funfarm/kit/Icon/icons/History';

import MetricsSection from 'components/Finances/MetricsSection';
import PlayersPackages from 'components/Finances/PlayerPackages/PlayersPackages';
import TransactionsHistory from 'components/Finances/TransactionsHistory/TransactionsHistory';
import TransfersHistory from 'components/Finances/TransfersHistory/TransfersHistory';

import useBalancesAndBrm from '../../hooks/useBalancesAndBrm';

import css from './finances.module.scss';

const FinanceMetrics: FC = () => {
    const {
        metrics,
        currentPackage,
        isLoading,
        isMetricsError,
        isCurrentPackageError,
    } = useBalancesAndBrm();

    const currentPackageProfit = useMemo(
        () => Number(currentPackage?.profit.profit),
        [currentPackage?.profit.profit],
    );

    return (isMetricsError || isCurrentPackageError) ? (
        <Error message="Ошибка получения данных" />
    ) : (
        <div className={css.financesContent}>
            <MetricsSection
                title="Текущий пакет"
                button="Пакеты игрока"
                buttonIcon={<IconDocumentText
                    size="medium"
                />}
                drawerContent={<PlayersPackages/>}
                isLoading={isLoading}
                items={[
                    { caption: "Дата начала пакета", value: metrics?.datepack },
                    { caption: "Процент от бай-ин", value: metrics?.percbuyin, percentage: true },
                    { caption: "Процент от профита", value: metrics?.percprofit, percentage: true },
                    { caption: "Сыграно МТТ в пакете", value: metrics?.mttpack },
                    { caption: "Профит пакета", value: currentPackageProfit, currency: "USD" },
                    {
                        caption: "Профит игрока",
                        value: currentPackageProfit > 0
                            ? currentPackageProfit * Number(metrics?.percprofit) / 100
                            : currentPackageProfit * Number(metrics?.percbuyin) / 100,
                        currency: "USD"
                    },
                    {
                        caption: "Профит проекта",
                        value: !metrics?.profit
                            ? undefined
                            : metrics?.profit > 0
                                ? metrics?.profit
                                : metrics?.profit,
                        currency: "USD"
                    },
                ]}
            />

            <MetricsSection
                title="Деньги игрока"
                button="История операций"
                buttonIcon={<IconHistory
                    size="medium"
                />}
                drawerContent={<TransfersHistory/>}
                isLoading={isLoading}
                items={[
                    { caption: "Нужно от игрока", value: metrics?.needuser, currency: "USD" },
                    { caption: "После расчёта", value: metrics?.wmoney, currency: "USD" },
                    { caption: "Доступно к выводу", value: metrics?.dvijpl, currency: "USD" },
                ]}
            />

            <MetricsSection
                title="Деньги проекта"
                button="История переводов"
                buttonIcon={<IconHistory
                    size="medium"
                />}
                drawerContent={<TransactionsHistory/>}
                isLoading={isLoading}
                items={[
                    { caption: "Нужно от проекта", value: metrics?.need, currency: "USD" },
                    { caption: "Средняя загрузка", value: metrics?.averload, currency: "USD" },
                    { caption: "Денег проекта у игрока", value: metrics?.moneyproject, currency: "USD" },
                    {
                        caption: "Излишки",
                        value: metrics?.overage,
                        currency: "USD",
                        ...(metrics?.overage && metrics.overage > 0 && {
                            className: css.redMetrics,
                            // button: (
                            //     <Button
                            //         view="outlined"
                            //         size="medium"
                            //         color="red"
                            //         label="К переводам"
                            //         onClick={() => navigate("/finance-requests")}
                            //     />
                            // ),
                        })},
                ]}
            />
        </div>
    );
};

export default FinanceMetrics;
