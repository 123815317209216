import { useLocation } from 'react-router-dom';

import { Button } from '@funfarm/kit';

import PageHeading from 'components/PageHeading/PageHeading';

import { TableColumnSettings } from './TableColumnSettings';

import css from './tournaments.module.scss'


export const TournamentsHeader = () => {
    const location = useLocation();


    const renderButtons = () => {
        switch(location.pathname) {
            case '/tournaments':
                return (
                    <>
                        {/*<Button iconLeft={<IconDocumentCopy size="large" />}>Копировать таблицу</Button>*/}
                        <TableColumnSettings />
                    </>
                );
            case '/tournaments/permissions':
                return <Button className={css.buttonPermission}>Запросить разрешение</Button>;
            default:
                return null;
        }
    };


    return (
        <PageHeading
            tabs={[
                { title: "Все", to: "/tournaments" },
                { title: "Лимиты для румов", to: "/tournaments/limits" },
            ]}
            title="ТУРНИРЫ"
            renderButtons={renderButtons}
        />
    )
}
