import React, { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import css from './pageHeading.module.scss';

export type PageHeadingProps = {
    title?: string;
    tabs?: {
        to: string;
        title: string;
    }[];
    renderButtons?: () => ReactNode;
    tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const PageHeading: FC<PageHeadingProps> = ({ title, tabs, renderButtons, tag }) => {

    const Tag = tag || "h1";
    return (
        <section className={css.header}>
            {title && (
                <Tag className={css.header__title}>
                    {title}
                </Tag>
            )}

            {tabs && (
                <div className={css.tabs}>
                    {tabs.map((tab, index) => (
                        <NavLink
                            key={index}
                            to={tab.to}
                            end
                            className={({ isActive }) =>
                                `${css.tab} ${isActive ? css.active : ""}`
                            }
                        >
                            {tab.title}
                        </NavLink>
                    ))}
                </div>
            )}

            {renderButtons && (
                <div className={css.button}>
                    {renderButtons()}
                </div>
            )}
        </section>
    );
}

export default PageHeading;
