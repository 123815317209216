import React from 'react';

import { TournamentsFilters } from './TournamentsFilters';
import { TournamentsTable } from './TournamentsTable';


export const TournamentsAll = () => {
    return (
        <>
            <TournamentsFilters />
            <TournamentsTable />
        </>
    )
}
