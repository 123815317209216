import React from "react";

import {TableCell, TableRow} from "@funfarm/kit";

import {ILimitsParams} from "types/index";

import css from './limits.module.scss'

interface LimitsTableRowProps {
    limit: ILimitsParams
}

export const LimitsTableRow:React.FC<LimitsTableRowProps> = ({limit}) => {
    return (
        <TableRow className={css.limitsRow}>
            <TableCell colspan={2}>{limit.exception}</TableCell>
            <TableCell colspan={2}>{limit.regko}</TableCell>
            <TableCell colspan={2}>{limit.turbo6ko}</TableCell>
            <TableCell colspan={2}>{limit.turbo9ko}</TableCell>
            <TableCell colspan={2}>{limit.reg6}</TableCell>
            <TableCell colspan={2}>{limit.reg9}</TableCell>
            <TableCell colspan={2}>{limit.turbo}</TableCell>
        </TableRow>
    )
}
