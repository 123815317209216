import React, { forwardRef,useState } from "react";

import { Input, InputProps } from './index';

export type NumberInputProps = InputProps & {
    min?: number;
    max?: number;
    precision?: number | null;
    value?: string;
    onChange?: (value: number | null) => void;
};

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
    (
        { min = -Infinity, max = Infinity, precision = null, value: initialValue = "", onChange, ...otherProps },
        ref
    ) => {
        const [value, setValue] = useState<string>(initialValue);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value;

            // Replace comma with a dot for consistency, allow both as decimal separators
            const sanitizedValue = inputValue.replace(/,/g, ".").replace(/[^0-9.,]/g, "");

            // Prevent multiple dots or commas
            const parts = sanitizedValue.split(/[.,]/);
            if (parts.length > 2) return;

            // Enforce precision during typing
            if (precision !== null && parts.length === 2 && parts[1].length > precision) {
                return;
            }

            let numericValue = parseFloat(sanitizedValue.replace(",", "."));

            // Apply min and max constraints
            if (!isNaN(numericValue)) {
                if (numericValue < min) numericValue = min;
                if (numericValue > max) numericValue = max;

                // Apply precision if specified
                if (precision !== null && !isNaN(precision)) {
                    numericValue = parseFloat(numericValue.toFixed(precision));
                }
            }

            const formattedValue = sanitizedValue === "" ? "" : numericValue.toString();

            setValue(formattedValue);

            // Trigger external onChange handler if provided
            if (onChange) {
                onChange(!isNaN(numericValue) ? numericValue : null);
            }
        };

        const handleBlur = () => {
            // Ensure the value is properly constrained when the user leaves the input
            if (value === "") return;
            let numericValue = parseFloat(value.replace(",", "."));
            if (numericValue < min) numericValue = min;
            if (numericValue > max) numericValue = max;

            if (precision !== null && !isNaN(precision)) {
                numericValue = parseFloat(numericValue.toFixed(precision));
            }

            const formattedValue = numericValue.toString();
            setValue(formattedValue);

            if (onChange) {
                onChange(numericValue);
            }
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            // Allow control keys (e.g., backspace, arrow keys, delete) and cmd/ctrl+A
            const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"];
            const isCmdA = (e.key === "a" || e.key === "A") && (e.metaKey || e.ctrlKey);

            if (
                allowedKeys.includes(e.key) ||
                isCmdA || // Allow cmd/ctrl + A
                (e.key === "." && !value.includes(".")) || // Allow one dot
                (e.key === "," && !value.includes(",")) || // Allow one comma
                (!isNaN(Number(e.key)) && e.key !== " ") // Allow numeric characters
            ) {
                return;
            }
            e.preventDefault(); // Block invalid input
        };

        return (
            <Input
                {...otherProps}
                type="text"
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                ref={ref}
            />
        );
    }
);

export default NumberInput;
