import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconHyperTurbo = (props: IconProps) => {
    return <Icon id="IconHyperTurbo" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.10549 20.4525L9.0552 14.6821C9.08547 14.4982 9.0334 14.3103 8.91281 14.1682C8.79221 14.0261 8.61527 13.9442 8.42892 13.9442H4.39686C3.46928 13.9442 2.9631 12.9387 3.4833 12.2131C3.49106 12.2022 3.49849 12.1912 3.50556 12.1799L9.02218 3.36144C9.06541 3.30604 9.10767 3.28587 9.14232 3.27728C9.186 3.26646 9.24171 3.26911 9.29822 3.2923C9.35474 3.3155 9.39576 3.35255 9.41874 3.39021C9.43804 3.42183 9.45459 3.46883 9.44233 3.54619L9.44215 3.54735L8.47718 9.35287C8.44659 9.53691 8.49849 9.72509 8.61909 9.86742C8.73968 10.0097 8.91677 10.0918 9.1033 10.0918H13.1099C14.0484 10.0918 14.5374 11.0759 14.0235 11.7927C14.0153 11.8041 14.0075 11.8157 14.0002 11.8276L8.525 20.6392C8.48189 20.6942 8.43977 20.7142 8.40523 20.7228C8.36153 20.7336 8.30578 20.7309 8.24925 20.7077C8.19271 20.6844 8.15171 20.6474 8.12876 20.6097C8.10945 20.578 8.09297 20.5303 8.10549 20.4525ZM6.85251 20.2489C6.59623 21.826 8.64252 22.6545 9.56613 21.3663C9.57428 21.355 9.58205 21.3433 9.58942 21.3315L15.068 12.5144C16.1931 10.9169 15.0217 8.82215 13.1099 8.82215H9.85224L10.6953 3.7499C10.95 2.17346 8.90474 1.34582 7.98145 2.63376C7.97369 2.64459 7.96627 2.65566 7.95921 2.66695L2.4393 11.4906C1.31947 13.0799 2.47402 15.2138 4.39686 15.2138H7.68119L6.85272 20.2476L6.85251 20.2489Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.9436 14.1814C17.2279 14.3864 17.6246 14.3221 17.8297 14.0377L21.6791 8.69813C22.4975 7.55793 21.6451 6.08819 20.3086 6.08819H18.1768L18.6111 3.34252L18.6113 3.34113C18.8063 2.12831 17.2349 1.50207 16.529 2.48554L16.5277 2.48733L13.9418 6.11652C13.7383 6.40204 13.8048 6.79846 14.0903 7.00194C14.3757 7.20542 14.7721 7.13891 14.9755 6.85339L17.2827 3.61535L16.8069 6.62383C16.7779 6.80724 16.8307 6.99416 16.9512 7.13535C17.0718 7.27654 17.2482 7.35785 17.4338 7.35785H20.3086C20.4963 7.35785 20.6225 7.4524 20.6836 7.56634C20.7431 7.677 20.7487 7.81693 20.6483 7.95719L16.8 13.2952C16.595 13.5795 16.6593 13.9763 16.9436 14.1814Z" />
    </Icon>;
};

export default IconHyperTurbo;
