import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconClock = (props: IconProps) => {
    return <Icon id="IconClock" viewBox="0 0 14 14"  {...props}>
        <path
            d="M6.99935 1.1665C5.84563 1.1665 4.71781 1.50862 3.75853 2.1496C2.79924 2.79057 2.05157 3.70161 1.61005 4.76752C1.16854 5.83342 1.05302 7.00631 1.2781 8.13786C1.50318 9.26942 2.05876 10.3088 2.87456 11.1246C3.69037 11.9404 4.72977 12.496 5.86132 12.7211C6.99288 12.9462 8.16577 12.8306 9.23167 12.3891C10.2976 11.9476 11.2086 11.2 11.8496 10.2407C12.4906 9.28138 12.8327 8.15356 12.8327 6.99984C12.8327 6.23379 12.6818 5.47525 12.3886 4.76752C12.0955 4.05978 11.6658 3.41672 11.1241 2.87505C10.5825 2.33337 9.9394 1.90369 9.23167 1.61054C8.52394 1.31739 7.7654 1.1665 6.99935 1.1665ZM9.33268 7.58317H6.99935C6.84464 7.58317 6.69627 7.52171 6.58687 7.41232C6.47748 7.30292 6.41602 7.15455 6.41602 6.99984V4.6665C6.41602 4.51179 6.47748 4.36342 6.58687 4.25402C6.69627 4.14463 6.84464 4.08317 6.99935 4.08317C7.15406 4.08317 7.30243 4.14463 7.41183 4.25402C7.52123 4.36342 7.58268 4.51179 7.58268 4.6665V6.4165H9.33268C9.48739 6.4165 9.63577 6.47796 9.74516 6.58736C9.85456 6.69675 9.91602 6.84513 9.91602 6.99984C9.91602 7.15455 9.85456 7.30292 9.74516 7.41232C9.63577 7.52171 9.48739 7.58317 9.33268 7.58317Z"
            fill="currentColor"
        />
    </Icon>;
};

export default IconClock;
