import React from 'react';

import { Icon, IconProps } from '../Icon';

interface IconCheckboxProps extends IconProps {
    selected?: boolean
}

export const IconCheckbox = (props: IconCheckboxProps) => {
    return (
        <Icon id="IconCheckbox" fill="none" viewBox="0 0 21 20" {...props}>
            {props.selected ? (
                <>
                    <path
                        d="M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V3Z"
                        fill="#1E70F0"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.9501 6.61386L9.3036 13.9673C9.10731 14.1561 8.79604 14.153 8.60347 13.9605L3.75 9.10702L4.45711 8.39992L8.96386 12.9067L16.257 5.89308L16.9501 6.61386Z"
                        fill="white"
                    />
                </>
            ) : (
                <>
                    <path
                        d="M1 3C1 1.61929 2.11929 0.5 3.5 0.5H17.5C18.8807 0.5 20 1.61929 20 3V17C20 18.3807 18.8807 19.5 17.5 19.5H3.5C2.11929 19.5 1 18.3807 1 17V3Z"
                        fill="#1C1E23"
                    />
                    <path
                        d="M1 3C1 1.61929 2.11929 0.5 3.5 0.5H17.5C18.8807 0.5 20 1.61929 20 3V17C20 18.3807 18.8807 19.5 17.5 19.5H3.5C2.11929 19.5 1 18.3807 1 17V3Z"
                        stroke="#444851"
                    />
                </>
            )}
        </Icon>
    );
};

export default IconCheckbox;
