import React, { ForwardedRef, forwardRef } from 'react';
import { Accept,useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import css from './area.module.scss';


interface IProps {
    accept?: Accept,
    label?: string,
    showFileNames?: boolean,
    disabled?: boolean,
    onChange?: (files: File[]) => void,
    uploadedFiles?: File[],
}

export const UploaderArea = forwardRef((props: IProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { accept, onChange, disabled, uploadedFiles } = props;
    const { t } = useTranslation();


    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            onChange && onChange(acceptedFiles);
        },
        accept,
    });


    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} ref={ref} />
            <div className={classNames(css.area, disabled && css.disabled)}>
                {t('Drag and drop files here or click to browse')}
            </div>
            <ul className={css.filesList}>
                {
                    uploadedFiles &&
                    uploadedFiles.map((file: File) => <li key={file.name}>{file.name}</li>)
                }
            </ul>
        </div>
    );
});
