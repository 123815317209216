import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconInfo = (props: IconProps) => {
    return <Icon id="IconInfo" {...props}>
        <path d="M13.25 6.75C13.25 6.05965 12.6903 5.5 12 5.5C11.3097 5.5 10.75 6.05965 10.75 6.75C10.75 7.44035 11.3097 8 12 8C12.6903 8 13.25 7.44035 13.25 6.75Z" />
        <path d="M10.25 10C10.25 9.44771 10.6977 9 11.25 9H12.25C12.8023 9 13.25 9.44771 13.25 10V16H14C14.5523 16 15 16.4477 15 17C15 17.5523 14.5523 18 14 18H10.5C9.94771 18 9.5 17.5523 9.5 17C9.5 16.4477 9.94771 16 10.5 16H11.25V11C10.6977 11 10.25 10.5523 10.25 10Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 1C8.96273 1 6.21136 2.23232 4.22183 4.22182C2.23233 6.21135 1 8.96273 1 12C1 15.0373 2.23231 17.7886 4.22182 19.7782C6.21135 21.7677 8.96273 23 12 23C15.0373 23 17.7886 21.7677 19.7782 19.7782C21.7677 17.7886 23 15.0373 23 12C23 8.96273 21.7677 6.21136 19.7782 4.22183C17.7886 2.23232 15.0373 1 12 1ZM5.63603 5.63604C7.26584 4.00626 9.51447 3 12 3C14.4855 3 16.7342 4.00626 18.3639 5.63604C19.9937 7.26584 21 9.51447 21 12C21 14.4855 19.9937 16.7342 18.3639 18.3639C16.7342 19.9937 14.4855 21 12 21C9.51447 21 7.26584 19.9937 5.63604 18.3639C4.00626 16.7342 3 14.4855 3 12C3 9.51447 4.00625 7.26585 5.63603 5.63604Z" />
    </Icon>;
};

export default IconInfo;
