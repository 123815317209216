import React, { ComponentProps } from 'react';

import { Icon } from '../Icon';


export const IconPlus = (props: ComponentProps<any>) => {
    return <Icon id="IconPlus" {...props}>
        <path d="M12 3C11.5858 3 11.25 3.33579 11.25 3.75V11.25H3.75C3.33579 11.25 3 11.5858 3 12C3 12.4142 3.33579 12.75 3.75 12.75H11.25V20.25C11.25 20.6642 11.5858 21 12 21C12.4142 21 12.75 20.6642 12.75 20.25V12.75H20.25C20.6642 12.75 21 12.4142 21 12C21 11.5858 20.6642 11.25 20.25 11.25H12.75V3.75C12.75 3.33579 12.4142 3 12 3Z" />
    </Icon>;
};

export default IconPlus;
