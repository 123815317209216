import React from "react";

import {PermissionTable} from "components/Tournaments/TournamentsPermissions/PermissionTable";

export const TournamentsPermissions = () => {
    return (
        <section>
            <h2>Разрешения на турниры</h2>
            <PermissionTable/>
        </section>
    )
}
