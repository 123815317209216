import React from 'react';

import { Button } from '@funfarm/kit';
import IconOn from '@funfarm/kit/Icon/icons/On';

import { money } from 'helpers/numbers';
import { BalanceHistoryItem, RoomBalance } from 'types/finances';

import { roomNames } from 'components/Finances/finances.mock';

import css from '../finances.module.scss';


const prepareBalanceHistoryColumns = (
    roomBalances: RoomBalance[],
) => ([
    {
        header: "Дата",
        fieldName: "date",
        colspan: 1,
    },
    {
        header: "Профит",
        fieldName: "profit",
        colspan: 1,
        renderCell: (item: BalanceHistoryItem) => (
            <>
                {money(item.profit, "USD")}
                {item.editable && (
                    <Button
                        view="light"
                        color="secondary"
                        size="small"
                        icon={
                            <IconOn
                                size="large"
                                className={Math.random() > 0.5 ? css.red : css.green}
                            />
                        }
                    />
                )}
            </>
        ),
    },
    ...(roomBalances.map(
        (roomBalance, roomIndex) => ({
            header: roomNames[roomBalance.room_id] || roomBalance.room_id,
            fieldName: `room-${roomBalance.room_id}`,
            colspan: 4,
            renderCell: ({ room_balances }: BalanceHistoryItem) => (
                <>
                    {money(room_balances[roomIndex].balance_usd, "USD")}
                    {(room_balances[roomIndex].allow_to_deactivate || room_balances[roomIndex].allow_to_activate) && (
                        <Button
                            view="light"
                            color="secondary"
                            size="small"
                            icon={
                                <IconOn
                                    size="large"
                                    className={
                                        room_balances[roomIndex].allow_to_deactivate ? css.red : css.green
                                    }
                                />
                            }
                        />
                    )}
                </>
            ),
        })
    )),
    {
        header: "Итого",
        fieldName: "total",
        colspan: 1,
        renderCell: (item: BalanceHistoryItem) => money(item.total, "USD"),
    },
]);

export default prepareBalanceHistoryColumns;
