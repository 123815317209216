import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session from 'supertokens-auth-react/recipe/session';

import { env } from 'env';
import { AuthProvider } from 'helpers';
import i18n from 'helpers/i18n';

import { Routes } from 'components/Routes';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';


Sentry.init({
    dsn: "https://6885fd833a2c578c7424f72c6d81e79c@o4505747824181248.ingest.us.sentry.io/4508122377879552",
    environment: "local",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/lk-web.funfarm\.dev\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


SuperTokens.init({
    appInfo: {
        // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
        appName: 'FunFarm',
        apiDomain: env.FF_API_URL,
        websiteDomain: env.FF_APP_URL,
        apiBasePath: '/api/auth',
        websiteBasePath: '/'
    },
    recipeList: [
        //EmailVerification.init({
        //    mode: 'OPTIONAL', // or "OPTIONAL"
        //}),
        EmailPassword.init({
            onHandleEvent: (context) => {
                if(context.action === 'PASSWORD_RESET_SUCCESSFUL') {
                    //
                } else if(context.action === 'RESET_PASSWORD_EMAIL_SENT') {
                    //
                } else if(context.action === 'SUCCESS') {
                    if(context.createdNewSession) {
                        // console.log('context.user', context.user);

                        if(context.isNewRecipeUser && context.user.loginMethods.length === 1) {
                            // sign up success
                        } else {
                            // sign in success
                        }
                    } else {
                        // this is during second factor login of step up auth flow
                    }
                }
            }
        }),
        Session.init({
            tokenTransferMethod: "header" // or "cookie"
        })
    ]
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 30, // 30 sec
            retry: false,
            refetchOnWindowFocus: false
        }
    }
});


export const App = () => {
    return (
        <>
            {/*<React.StrictMode>*/}
            <I18nextProvider i18n={i18n}>
                <SuperTokensWrapper>
                    <AuthProvider>
                        <QueryClientProvider client={queryClient}>
                            <Routes />
                            <ReactQueryDevtools initialIsOpen={false} />
                        </QueryClientProvider>
                    </AuthProvider>
                </SuperTokensWrapper>

                <ToastContainer position="top-right" autoClose={3000} hideProgressBar closeOnClick rtl={false} draggable={false} icon={false} pauseOnHover />
            </I18nextProvider>
            {/*</React.StrictMode>*/}
        </>
    );
};

root.render(App());
