import React, { ElementType } from 'react';

import { Table, TableCell, TableHeader, TableRow } from '@funfarm/kit';

export type ColumnConfig<T> = {
    header: string;
    fieldName?: keyof T;
    renderCell?: (item: T, column?: ColumnConfig<T>) => JSX.Element;
    colspan?: number;
    sortable?: boolean;
};

type TableRendererProps<T> = {
    columns: ColumnConfig<T>[];
    rows: T[];
    classNames?: Record<"header" | "row", string>,
};

const TableRenderer: ElementType = <T extends Record<string, unknown>> ({
    columns,
    rows,
    classNames,
}: TableRendererProps<T>) => {
    return (
        <>
            <TableHeader className={classNames?.header}>
                {columns?.map((column, index) => (
                    <TableCell
                        colspan={column.colspan || 1}
                        key={index}
                    >
                        {column.header}
                        {column.fieldName && column.sortable !== false && (
                            <Table.ActionSort fieldName={column.fieldName as string} />
                        )}
                    </TableCell>
                ))}
            </TableHeader>

            {rows?.map((item: T, index) => (
                <TableRow
                    className={classNames?.row}
                    key={index}
                >
                    {columns?.map((column, colIndex) => (
                        <TableCell
                            colspan={column.colspan || 1}
                            key={colIndex}
                        >
                            {column.renderCell
                                ? column.renderCell(item, column)
                                : column.fieldName
                                    ? item[column.fieldName] as string
                                    : ""
                            }
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </>
    );
};

export default TableRenderer;
