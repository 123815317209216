import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { EOrderDirection, IFilteredBy, IOrderedBy, IPaginationParams } from '@funfarm/kit/types';


interface ITableOptions {
    order?: IOrderedBy,
    filter?: IFilteredBy,
    pagination?: IPaginationParams
}


export const useTable = (options?: ITableOptions) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [orderedBy, setOrderedBy] = useState<IOrderedBy>(options?.order ?? { 'name': EOrderDirection.asc });
    const [filteredBy, setFilteredBy] = useState<IFilteredBy>(options?.filter ?? {});
    const [rowsChecked, setRowsChecked] = useState<Record<string, any>[]>([]);
    const [tableColumns, setTableColumns] = useState<string[]>([]);
    const [pagination, setPagination] = useState<IPaginationParams>(options?.pagination ?? { take: 5, skip: 0 });


    const orderBy = useCallback((fieldName: string, orderDirection: EOrderDirection) => {
        setOrderedBy({ [fieldName]: orderDirection });

        searchParams.delete('orderBy');
        searchParams.delete('orderType');

        // got to page 1
        searchParams.delete('page');
        // onPageChange(1);

        searchParams.set('orderBy', fieldName);
        searchParams.set('orderType', orderDirection);

        setSearchParams(searchParams);
    }, [setOrderedBy, searchParams, setSearchParams]);


    const filterBy = useCallback((fieldName: string, filterValues?: IFilteredBy) => {
        setFilteredBy(prevFiltered => {
            if(filterValues)
                return ({ ...prevFiltered, ...filterValues });

            delete prevFiltered[fieldName];

            return prevFiltered;
        });

        searchParams.delete(fieldName);

        // got to page 1
        searchParams.delete('page');

        if(filterValues) {
            for(const filterName of Object.keys(filterValues)) {
                if(filterValues[filterName])
                    searchParams.set(fieldName, filterValues[filterName] as string);
            }
        }

        setSearchParams(searchParams);
    }, [setFilteredBy, searchParams, setSearchParams]);


    const clearFilters = () => {
        for(const fieldName of Object.keys(filteredBy)) {
            searchParams.delete(fieldName);
        }

        // got to page 1
        searchParams.delete('page');

        setFilteredBy({});
        setSearchParams(searchParams);
    };


    return {
        orderedBy,
        setOrderedBy,
        orderBy,
        filteredBy, setFilteredBy,
        filterBy, clearFilters,
        rowsChecked, setRowsChecked,
        tableColumns, setTableColumns,
        pagination, setPagination
    };
};
