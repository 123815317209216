import React from "react";
import {useTranslation} from "react-i18next";
import cn from "classnames";
import classNames from "classnames";
import {format} from "date-fns";

import {Button, TableCell, TableRow} from "@funfarm/kit";

import {IPermissionsParams} from "types/index";

import css from "components/Tournaments/TournamentsAll/table.module.scss";

interface PermissionTableRowProps {
    permission: IPermissionsParams
}

export const PermissionTableRow:React.FC<PermissionTableRowProps> = ({permission}) => {
    const { t } = useTranslation();

    return (
        <TableRow className={cn(css.tournamentsRow)}>
            <TableCell id="room">{permission.room}</TableCell>
            <TableCell colspan={4} id="name" nowrap>{permission.name}</TableCell>
            <TableCell colspan={2} id="date_tourney" align="center">{permission.date_tourney &&  format(permission.date_tourney, 'yyyy-MM-dd')}</TableCell>
            <TableCell colspan={2} id="bi" align="center">{permission.bi}</TableCell>
            <TableCell id="answer">
                <span
                    className={classNames(css.permissionAnswer, permission.action ? css.permissionAnswerRed : css.permissionAnswerGreen)}
                >
                    {permission.answer}
                </span>
            </TableCell>
            <TableCell colspan={3} id="action" align="right">
                {permission.action && <><Button
                    color="secondary"
                    size="small"
                    className={classNames(css.button)}
                >
                    {t('Will play')}
                </Button>
                <Button
                    color="red"
                    view="outlined"
                    size="small"
                    className={css.button}
                >
                    {t('Reject')}
                </Button>
                </>}
            </TableCell>
        </TableRow>
    )
}
