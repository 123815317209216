import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconTurbo = (props: IconProps) => {
    return <Icon id="IconFilterColumn" {...props}>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M11.328 20.4525C11.328 20.4527 11.328 20.4524 11.328 20.4525L12.3123 14.6821C12.3437 14.4982 12.2897 14.3103 12.1647 14.1682C12.0398 14.0261 11.8564 13.9442 11.6632 13.9442H7.48421C6.52282 13.9442 5.9982 12.9387 6.53735 12.2131C6.54541 12.2022 6.5531 12.1912 6.56043 12.1799L12.2781 3.36144C12.3229 3.30604 12.3667 3.28587 12.4026 3.27728C12.4479 3.26646 12.5056 3.26911 12.5642 3.2923C12.6228 3.31549 12.6653 3.35255 12.6891 3.39021C12.7091 3.42183 12.7263 3.46883 12.7136 3.54619C12.7135 3.54658 12.7134 3.54696 12.7134 3.54735L11.7132 9.35287C11.6815 9.53691 11.7353 9.72509 11.8603 9.86742C11.9853 10.0097 12.1688 10.0918 12.3622 10.0918H16.5148C17.4875 10.0918 17.9944 11.0759 17.4616 11.7927C17.4532 11.8041 17.4452 11.8157 17.4375 11.8276L11.7628 20.6392C11.7181 20.6942 11.6745 20.7142 11.6387 20.7228C11.5934 20.7336 11.5356 20.7309 11.477 20.7077C11.4184 20.6844 11.3759 20.6474 11.3521 20.6097C11.3321 20.578 11.315 20.5303 11.328 20.4525ZM10.0294 20.2489C9.76374 21.826 11.8846 22.6545 12.8419 21.3663C12.8503 21.355 12.8584 21.3433 12.866 21.3315L18.5443 12.5144C19.7104 10.9169 18.4962 8.82215 16.5148 8.82215H13.1384L14.0122 3.7499C14.2762 2.17346 12.1564 1.34582 11.1994 2.63376C11.1914 2.64459 11.1837 2.65566 11.1764 2.66695L5.45531 11.4906C4.29466 13.0799 5.4913 15.2138 7.48421 15.2138H10.8882L10.0296 20.2476L10.0294 20.2489Z" />
    </Icon>;
};

export default IconTurbo;
