import React, { useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';

import { IError, IPaginationParams, Loader, Table, TableCell, TableContext, TableHeader } from '@funfarm/kit';

import { ITournament, TPaginationResponse } from 'types';
import { getSchedule } from 'actions';

import { FloatingAction } from 'components/Tournaments/Fab';
import { TourContext } from 'components/Tournaments/TourProvider';

import { TournamentRow } from './TournamentRow';

import css from './table.module.scss';


export const ScheduleTable = () => {
    const { ref, inView } = useInView();

    const { filterParams } = useContext(TourContext);
    const { orderedBy, pagination, rowsChecked } = useContext(TableContext);


    const { isLoading, data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery<TPaginationResponse<'tourneys', ITournament>, IError, { pages: TPaginationResponse<'tourneys', ITournament>[] }>({
        queryKey: ['schedule', filterParams, orderedBy],
        queryFn: ({ pageParam }) => {
            const paginationParams = pageParam as IPaginationParams ?? pagination;

            return getSchedule({ ...filterParams, selected: false }, orderedBy, paginationParams)
        },
        getNextPageParam: (lastPage, allPages) => {
            const totalLoaded = allPages.flatMap(page => page.tourneys).length;

            return totalLoaded < lastPage.total ? { take: pagination?.take, skip: totalLoaded } : undefined;
        },
        initialPageParam: pagination,
        refetchInterval: 5 * 60 * 1000
    });


    useEffect(() => {
        if(inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, fetchNextPage]);


    return (
        <>
            <TableHeader className={css.tournamentsSplitRow}>
                <span className={css.color} />
                <TableCell actions={1}>
                    <Table.ActionCheckbox rowId="all" disabled={!data?.pages.flatMap(page => page.tourneys).length}/>
                </TableCell>
                <TableCell>Все турниры</TableCell>
            </TableHeader>

            {
                isLoading &&
                <Loader size="small" caption="Fetching table data..." className={css.loader} />
            }
            {
                data &&
                data?.pages.flatMap(page => page.tourneys).map((tournament: any) => <TournamentRow key={tournament.id} tournament={tournament} />)
            }
            {
                rowsChecked &&
                <FloatingAction open={!!rowsChecked.length} />
            }
            {
                !isLoading && data &&
                <div ref={ref}>
                    {
                        (isFetchingNextPage) ?
                            <Loader size="xsmall" caption="Fetching table data..." className={css.loader} /> :
                            null
                    }
                </div>
            }
        </>
    );
};
