import React from 'react';

import { Badge } from '@funfarm/kit';

import { timestampToDate } from 'helpers/formatDates';
import { money, symbolToCurrencyCode } from 'helpers/numbers';
import { FinanceTransaction } from 'types/finances';

import css from '../finances.module.scss';

const transferColumns = [
    {
        header: 'Дата',
        colspan: 2,
        fieldName: 'date',
        renderCell: (item: FinanceTransaction) => timestampToDate(item.date),
    },
    {
        header: 'Отправитель',
        colspan: 2,
        fieldName: 'fromname',
    },
    {
        header: 'Получатель',
        colspan: 2,
        fieldName: 'toname',
    },
    // {
    //     header: "$ проекта",
    //     fieldName: "tobalance",
    // },
    {
        header: 'Сумма, $',
        fieldName: 'amount',
        colspan: 2,
        renderCell: (item: FinanceTransaction) => (
            <span className={Number(item.amount) > 0 ? css.green : css.red}>
                {Number(item.amount) > 0 && "+ "}
                {money(
                    item.amount,
                    symbolToCurrencyCode(item.currency)
                )}
            </span>
        ),
    },
    {
        header: 'Валюта',
        fieldName: 'currency',
        renderCell: (item: FinanceTransaction) =>
            symbolToCurrencyCode(item.currency),
    },
    {
        header: "Счёт",
        fieldName: "room",
        colspan: 2,
    },
    {
        header: 'Тип',
        fieldName: 'type',
        colspan: 2,
    },
    {
        header: 'Статус',
        fieldName: 'modcom',
        colspan: 2,
        renderCell: (item: FinanceTransaction) => {
            return (
                <Badge size="small" color={getColor(item.modcom)}>
                    {item.modcom}
                </Badge>
            );
        },
    },
    // {
    //     header: 'Коммент',
    //     fieldName: 'comment',
    // },
];

const getColor = (status: string) =>
    status === 'Проведено'
        ? 'green'
        : status.includes('Удален')
            ? 'red'
            : 'default';

export default transferColumns;
