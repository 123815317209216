import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import { Badge, IOption } from '@funfarm/kit';

import { IMultiSelectProps } from '../MultiSelect';

import css from './selected-badges.module.scss';


interface SelectedBadgesProps extends Pick<IMultiSelectProps, 'maxBadges' | 'disabled' | 'readonly'> {
    values: (string | number)[];
    options: IOption[];
    handleDelete: (optionValue: string | number) => MouseEventHandler<HTMLElement>;
}


export const SelectedBadges = (props: SelectedBadgesProps) => {
    const {
        values,
        maxBadges = values.length,
        disabled,
        readonly,
        options,
        handleDelete
    } = props;

    return (
        <>
            {
                values.slice(0, maxBadges).map((item: string | number) => {
                    const option = options
                        .find((option) => option.value.toString() === item.toString());

                    return (
                        <Badge
                            className={classNames(
                                css.badge,
                                css.truncate,
                                disabled && css.disabled
                            )}
                            size="small"
                            key={item}
                            label={option?.label as string}
                            title={item as string}
                            {...(disabled || readonly ? {} : { onDelete: handleDelete(item) })} />
                    )
                })
            }
            {
                values.length > maxBadges &&
                <Badge
                    className={classNames(css.badge, disabled && css.disabled)}
                    size="small"
                    label={'+' + (values.length - maxBadges)}
                    title={values.slice(maxBadges).join(', ')} />
            }
        </>
    );
};
