import { IOrderedBy, IPaginationParams } from '@funfarm/kit';

import { API } from 'helpers';
import { IFilterParams, IFilterTemplate, ITournament, TPaginationResponse } from 'types';


export const getSchedule = (params?: IFilterParams, orderedBy?: IOrderedBy, pagination?: IPaginationParams) => {
    const orderParams: { order_by?: string, order_dir?: 'asc' | 'desc' } = {};

    if(orderedBy) {
        const orderKey = Object.keys(orderedBy)[0];

        orderParams.order_by = orderKey;
        orderParams.order_dir = orderedBy[orderKey];
    }

    const requestParams = {
        ...params,
        bi_start: params?.bi_start || 0,
        ...orderParams,
        ...pagination
    };

    if(params?.rooms_id && params?.rooms_id.length)
        requestParams.rooms_id = params.rooms_id.toString().split(',');
    else
        delete requestParams.rooms_id;

    if(params?.tourney_type && params?.tourney_type.length)
        requestParams.tourney_type = params.tourney_type.toString().split(',');
    else
        delete requestParams.tourney_type;

    return API.get<typeof requestParams, TPaginationResponse<'tourneys', ITournament>>('/check/tourneys/schedule', { params: requestParams, timeout: 1000 * 60 });
}


export const getFilters = () => {
    return API.get<any, { filters: [] }>('/check/tourneys/filters')
        .then(response => response.filters);
}


export const getRooms = () => {
    return API.get<any, { rooms: [] }>('/check/library/rooms')
        .then(response => response.rooms);
}


export const getTourneyTypes = () => {
    return API.get<any, any>('/check/library/tourney-types')
        .then(response => response.types);
}


export const getPermissions = () => {
    return API.get<any, { data: [] }>('/check/tourneys/permissions')
        .then(response => response.data);
}


export const getLimits = () => {
    return API.get<any, { data: [] }>('/check/tourneys/limits')
        .then(response => response.data);
}


export const postFilters = (data: IFilterTemplate) => {
    const { name, ...value } = data;

    return API.post('/check/tourneys/filters', { name, value });
}


export const putFilters = (data: IFilterTemplate) => {
    const { id, name, ...value } = data;

    return API.put(`/check/tourneys/filters/${id}`, { name, value: JSON.stringify(value) });
}


export const deleteFilters = (data: IFilterTemplate) => {
    const { id } = data;

    return API.delete(`/check/tourneys/filters/${id}`);
}


export const postWillPlay = (rows: Record<string, any>[]) => {
    return API.post('/check/tourneys/select', { tourney_ids: rows });
}


export const postWillNotPlay = (rows: Record<string, any>[]) => {
    return API.post('/check/tourneys/unselect', { tourney_ids: rows.map(r => r.id) });
}


export const postTourneysHide = (rows: Record<string, any>[]) => {
    return API.post('/check/tourneys/hide', { tourney_ids: rows.map(r => r.id) });
}


export const postTourneysShow = (rows: Record<string, any>[]) => {
    return API.post('/check/tourneys/unhide', { tourney_ids: rows.map(r => r.id) });
}
