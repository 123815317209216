import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import { Checkbox, IEventTarget, TableContext } from '@funfarm/kit';


interface IProps {
    rowId: string | number,
    rowData?: Record<string, any>,
    disabled?: boolean
}

export const ActionCheckbox = (props: IProps) => {
    const { rowId, rowData, disabled } = props;
    const { rowsChecked, setRowsChecked } = useContext(TableContext);

    const [key, setKey] = useState<number>(0);


    useEffect(() => {
        if(rowsChecked?.find(r => r.id === 'all'))
            handleChange({ target: { name: rowId.toString(), value: rowId.toString() }});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsChecked]);


    const handleChange = (event: ChangeEvent<HTMLInputElement> | IEventTarget) => {
        const value = event.target.value;

        setRowsChecked &&
        // @ts-expect-error can't be undefined
        setRowsChecked((prevRows) => {
            if(value === 'all') {
                if(!prevRows?.find(r => r.id === 'all'))
                    return [{ id: 'all' }];
                else
                    return [];
            }

            if(prevRows?.find(r => r.id === 'all'))
                prevRows = [];

            // with rowData
            return (
                prevRows?.find(r => r.id === rowData?.id) ?
                    prevRows.filter(row => row.id !== value.toString()) :
                    [...prevRows!, rowData]
            );
        });

        setKey(prevKey => prevKey + 1);
    };


    return (
        <>
            {
                rowId !== 'all' ?
                    <Checkbox onChange={handleChange} value={rowId} key={`${key}${rowId}`} checked={!!rowsChecked?.find(r => r.id === rowId.toString() || r.id === 'all')} disabled={disabled} /> :
                    <Checkbox onChange={handleChange} value="all" checked={!!rowsChecked?.find(r => r.id === 'all')} disabled={disabled} />
            }
        </>
    );
};
