import { FC } from 'react';

import { Row, Skeleton } from '@funfarm/kit';
import { Column } from '@funfarm/kit/Column';

import css from './finances.module.scss';

type TableSkeletonProps = {
    rows?: number;
    columns?: number;
};

const TableSkeleton: FC<TableSkeletonProps> = ({
    rows = 10,
    columns = 5,
}) => (
    <Column>
        {new Array(rows).fill(null).map((_, i) => (
            <Row key={i} gap={1}>
                {new Array(columns).fill(null).map((_, j) => (
                    <Skeleton
                        className={css.tableSkeleton}
                        key={i + '_' + j}
                    />
                ))}
            </Row>
        ))}
    </Column>
);

export default TableSkeleton;
