import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconDeviceMobile = (props: IconProps) => {
    return <Icon id="IconDocumentCopy" {...props} viewBox='0 0 32 32' >
        <path
            fill='none'
            d="M16 24H16.0133M10.6667 28H21.3333C22.8061 28 24 26.8061 24 25.3333V6.66667C24 5.19391 22.8061 4 21.3333 4H10.6667C9.19391 4 8 5.19391 8 6.66667V25.3333C8 26.8061 9.19391 28 10.6667 28Z"
            stroke="#22B183" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Icon>;
};

export default IconDeviceMobile;
